/* copied from wordpress site to keep the same styling */

/* Cookiebot styling */
#CybotCookiebotDialog {
  #CybotCookiebotDialogPoweredbyLink {
    background-image: url(/favicon.ico);
    background-repeat: no-repeat;
    background-size: 25px;
    height: 25px;
    width: 100%;
    
    @media (min-width: 1280px) {
      background-position: center;
      background-size: 30px;
      height: 30px;
    }

    #CybotCookiebotDialogPoweredbyImage {
      display: none !important;
    }
  }

  .CybotCookiebotDialogBodyButton {
    border-color: #ef9008 !important;

    &#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      background-color: #ef9008 !important;
    }
  }

  input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #ef9008 !important;
  }
  
  a, 
  #CybotCookiebotDialogBodyEdgeMoreDetails a {
    color: #ef9008 !important;
  }
  
  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    color: #ef9008 !important;
    border-bottom: 1px solid #ef9008 !important;
  }

  &[data-template="popup"] {
    #CybotCookiebotDialogPoweredbyLink {
      background-position: left !important;
      background-size: 25px !important;
      height: 25px !important;
    }
  }

   :focus-visible {
    outline: 2px solid #ef9008 !important;
  }
}