.onboarding {
  font-size: 18px;

  //   .stickyTop {
  //       position: fixed;
  //       width: 100%;
  //       top:0;
  //       z-index:500;
  //       background: #fff;
  //   }
  
  input[type=number].form-control {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    & {
      -moz-appearance: textfield;
    }
  }

  .backnotallowed {
    cursor: not-allowed;
  }

  .canvas {
    padding-bottom: 264px;
    // padding-top: 357px;
  }

  .activateHeader {
    color: #e9961d;
    font-size: 20px;
    font-weight: bold; 
    padding: 18px 40px;
    border-bottom: 1px solid #36b8c3;
    margin-bottom: 30px;
  }

  .collapseHeader {
    display: block;
    font-size: 22px;
    padding: 30px 0;
    border-bottom: 4px solid #32bbc5;
    margin-bottom: 50px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .collapseHeader:after,
  .collapseHeader[aria-expanded="false"]:after {
      /* symbol for "opening" panels */
      font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
      content: "\e114";    /* adjust as needed, taken from bootstrap.css */
      float: right;        /* adjust as needed */
      color: grey;         /* adjust as needed */
  }
  .collapseHeader[aria-expanded="true"]:after {
      /* symbol for "collapsed" panels */
      content: "\e113";    /* adjust as needed, taken from bootstrap.css */
  }
  .collapseHeader[aria-expanded="true"].done:after,
  .collapseHeader[aria-expanded="false"].done:after {
    /* symbol for "collapsed" panels */
    content: "\e013";    /* adjust as needed, taken from bootstrap.css */
  }

  .top-content {
    margin-top: 60px;
    padding-bottom: 30px;
  }
  .middle-content {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #e4f4f5;
  }
  .bottom-content {
    padding-top: 40px;
  }
  .topbar {
    padding: 10px 25px;
    background-color: #cef2f4;
    .btn {
      font-size: 14px;
    }
  }
  .progress {
    height: auto;
  }
  .passwordCheckList {
    padding-left: 0px;
    padding-top: 20px;
    list-style: none;
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 5px;
      &:before {
        content: "•\0020";
        font-size: 30px;
        position: absolute;
        left: 9px;
        top: -3px;
        color: #09bdc6;
      }
      &.ok:before {
        content: "";
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNDhCMTY1N0Q2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNDhCMTY1OEQ2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjI0OEIxNjU1RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjI0OEIxNjU2RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C7PaoQAAASRJREFUeNpi/P//PwM1gNrCjnYgFQvEB4A4jYVKhk4GUjlQbjQQszJS6mKgoVOAVDaa8B8mKrg0G4vURiYKDJ2I5H1ksBOIE5goMDQPi9RuIA6+FV/xhQWqkAtIaQDxVaDgTwoMDQTq/wriMAEVykKTyFmQJJAvSiBM83B4PwhmKNhgIF4CxKZQvi0QbwEaIEYgSSGDHUAcCvI+siDIYAM0hWZAvAlokDgRhu6EGvoZXYJRdUF7JZBuw6LpBBBHAnEuEBcRClMMg0EZBOiiOiC7EYv8WyAWxuHSYFyGwg2GeheX4bgi6hs+RfB0DFTYBKTqCRi6HYhDCBmKYjARhoNiPxw99okyGMnwRizeD8MW+wTDGEu6rYdmhhNQQ7+Sku0BAgwAqNZ6u3oIY6kAAAAASUVORK5CYII=) !important;
        background-size: contain;
        background-repeat: no-repeat;
        width: 14px;
        height: 11px;
        position: absolute;
        left: 7px;
        top: 3px;
        color: #25a087;
      }
    }
  }
  .btn.text-left {
    text-align: left;
  }
  .progress-step.is-complete.hourglass-step .progress-marker::before,
  .progress-step.is-progress.hourglass-step .progress-marker::before {
    background-color: #00b119;
    color: #fff;
  }
  ol {
    counter-reset: ol;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 50px;
    &.orange {
      li:before {
        background-color: #e48600;
      }
    }
    li {
      position: relative;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        counter-increment: ol;
        background-color: #09bdc6;
        content: counter(ol);
        position: absolute;
        left: -52px;
        top: -6px;
        border-radius: 100vh;
        padding: 8px 0;
        text-align: center;
        height: 40px;
        width: 40px;
        margin-right: 15px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .iconWrapper {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;
    .icon {
      width: 100px;
      height: 100px;
      margin: auto;
      margin-bottom: 15px;
      background: url("/images/zandloper.png");
      background-size: cover;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      &.done {
        animation: none;
        animation-name: none;
        background: url("/images/check.png");
        background-size: cover;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/** If we want to enable the new form style for the whole portal
just remove the .onboarding. */
.onboarding {
  font-family: $font-family-headings;
  .mx-auto {
    margin: auto;
    float: none;
  }
  .max-width-600 {
    max-width: 600px;
  }
  .max-width-450 {
    max-width: 450px;
  }
  .max-width-350 {
    max-width: 350px;
  }
  /** Bootstrap override, because .panel is used to work around
  the issue with accordion and panel */
  .panel-discreet {
    border: none;
    background: none;
    box-shadow: none;
  }
  .form-hr {
    margin-top: 0px;
    margin-bottom: 40px;
    // border: 0;
    border-top: 1px solid #32bbc5;
  }
  .borderedListItem {
    display:flex;
    border-bottom: 1px solid #32bbc5;
    // padding-bottom: 30px;
    margin-bottom: 30px;
    & > span {
      margin-bottom: 30px;
    }
    .form-group {
      p {
        font-weight: 400;
      }
    }
  }
  .card {
    padding: 40px 20px;
    border: 2px solid #e4bd7a;
    background: #e4d2ae;
  }
  .form-group {
    position: relative;
    margin-bottom: 30px;
    &.has-success {
      .form-control {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNDhCMTY1N0Q2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNDhCMTY1OEQ2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjI0OEIxNjU1RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjI0OEIxNjU2RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C7PaoQAAASRJREFUeNpi/P//PwM1gNrCjnYgFQvEB4A4jYVKhk4GUjlQbjQQszJS6mKgoVOAVDaa8B8mKrg0G4vURiYKDJ2I5H1ksBOIE5goMDQPi9RuIA6+FV/xhQWqkAtIaQDxVaDgTwoMDQTq/wriMAEVykKTyFmQJJAvSiBM83B4PwhmKNhgIF4CxKZQvi0QbwEaIEYgSSGDHUAcCvI+siDIYAM0hWZAvAlokDgRhu6EGvoZXYJRdUF7JZBuw6LpBBBHAnEuEBcRClMMg0EZBOiiOiC7EYv8WyAWxuHSYFyGwg2GeheX4bgi6hs+RfB0DFTYBKTqCRi6HYhDCBmKYjARhoNiPxw99okyGMnwRizeD8MW+wTDGEu6rYdmhhNQQ7+Sku0BAgwAqNZ6u3oIY6kAAAAASUVORK5CYII=") !important;
        background-repeat: no-repeat;
        background-position: right 18px center !important; // DIRTY
        background-size: auto !important; // DIRTY
      }
      .error-help-block {
        display: none !important;
      }
    }
    &.has-error {
      .form-control {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRDVBRjJEM0Q2NDMxMUVBOEI0NDk4MzU2N0QwQzA1QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRDVBRjJENEQ2NDMxMUVBOEI0NDk4MzU2N0QwQzA1QiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJENUFGMkQxRDY0MzExRUE4QjQ0OTgzNTY3RDBDMDVCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJENUFGMkQyRDY0MzExRUE4QjQ0OTgzNTY3RDBDMDVCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+hC7q/wAAAf9JREFUeNp0lL1uE1EQhefMXSOTlgIpDRLKC9AQAV2EHQoMRRpEAIHWBW/AK/AINFkFkSDRgVOQpEE8Ag0dDXQUEYgfY5s7h7neNf7bXGlW9+7Ofjsz59gYbFw5O8DoSKFrCj4GpCfjhTJoHrHaq4imIzdNUJhlX86sDFtZhL2DYF0oYsQbVd5y0IHUrZK5adDDtIfE1TgM7zWqrIPTPDP0SHTqGKS0vILD8uAQpdhfXNLG12/bttKcSzYTB0ln3NEEIGg74Hgur9+UxrnvuWp/8FKR3Vv86hhk0oGX6ZVdN+rRYo4ydENzVCizIIjch6EGxJ6399QkHC8BVHIN3KHPQycDg0ktKBJPKqmmAEOurs54MOksfb+GavgR+x4PfPtzRpDZ9duV6HoUnIXKhfTIb2UeDR9d4At/8dkpCu+6BXboeSUdFeSiX39VkMyEgW3f3a2VWGTLMt6UBmXSSgn5U7XDZCS0GJFUWJX6dd6iHLiB53z0f7DJSA5ZUgHEbq38nIIUwVIFN5YBbi8JD9X0kSt3p95HuJ3eVxtmbSPfLgOy3Kt4TjebS/+qXn57HYdZBz82LhNYAGgCaJE+l/osY/wj3qZyb2HYyXj8NKs5VHPXuajTtzLk/ZmBJeN91qCjq2T44MwTQLuiCWBy2l+Bg/Y8EujEAj5qjNf+CTAAe8r2aFrXcJkAAAAASUVORK5CYII=");
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: auto;
        border-color: #f44336;
      }
      .error-help-block {
        display: block !important;
        // position: absolute; 
        // top: 100%;
        padding-left: 45px;
        padding-right: 18px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NDBBRjRCNkQ2NDMxMUVBOTZEN0Y1M0MyN0E5REVDNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NDBBRjRCN0Q2NDMxMUVBOTZEN0Y1M0MyN0E5REVDNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ0MEFGNEI0RDY0MzExRUE5NkQ3RjUzQzI3QTlERUM2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ0MEFGNEI1RDY0MzExRUE5NkQ3RjUzQzI3QTlERUM2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+P+9OrAAAAWlJREFUeNqklE0oRGEUhu98xiwodlKyEFlJWSCFDQsxGzZSkpSyVX7KwlKRpYUd8rewQ6KUIotZshgrWyyNhszCXM+ZjrrG/b475dTTmZlz7jvvvfecL+b7vheM9/7OBKkNhqEH6qEcXiENp3BReZl6Cl4XCwoh0kBagglIePYQwVXYQzD/SwgRcbENrV7psQbLiOWMijSSdiwid3AAnyG1BZgrOMr2dcgtbMC05V8H4QqOIBlS/4ABow920mE/i3Vxk7HUK2BGhEb0rdiiTLNx9CSl2Ov9P6pEqC6iyS/KoWEibssLvC2nUBzeoNbRM8p41JDboxylIxzNwjE0RwmdOOo5XZkhuHatjAidw4Ol4RE2maMznXxbbBnd4hXIhzQ0wRTPqIs8bhG5gf24fjmEFlgsapL1WXc4EcfzmHkuTCsfvmSLlUyJQ3gLY1yb+nMe6UnQLbujC1odInAv5xDsIvLy8+O3AAMAZ8hjR5XS3AkAAAAASUVORK5CYII=');
        background-repeat: no-repeat;
        background-position: left 18px top;
        font-size: 13px;
        margin-top: 12px;
        // line-height: 18px;
        // min-height: 18px;
      }
      input[type=checkbox]:focus, 
      input[type=file]:focus, 
      input[type=radio]:focus {
        outline: none;
      }
    }
    .help-block {
      padding-left: 45px;
      padding-right: 18px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozODMzREQ3RkQ2NDMxMUVBOERBREY4RTI4NEQ2OEY1QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozODMzREQ4MEQ2NDMxMUVBOERBREY4RTI4NEQ2OEY1QSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjM4MzNERDdERDY0MzExRUE4REFERjhFMjg0RDY4RjVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjM4MzNERDdFRDY0MzExRUE4REFERjhFMjg0RDY4RjVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+r6gHvwAAAYVJREFUeNpi/P//PwM64Np33BFIeQCxNxBrQ4VvAfEOIN75zclyG7oeRmSDgAZIAanJQBzEgB/sBeJcoIHXYQJMSIboAamraIb0ADEfFHchiTsD8RWgHmcUg4ACQkDqCBALoNl8GWjrZxCGWoIMQHr3APUqgzgsUMGlQMyLxQvVQIWmULYFDm+CwkudkXPvMXMg4wQWBb+AmI2BOOAPcl4EFonvQKyFFD4g3IvHoCKQ11ywSPwAhstdtCTxAo9BGiAXKWOR4AZqDAdiJSQxHjwG8YAMYsQiAQqbFUCcQ2QYgaPwER75L0Sa8xVk0H48CsSQ2BJ41D0AGbQKi8RvaKpegSS2Apq6f2NRPxWc14CBehrIMUGS+ACMNUFsVgPVvkfLAa+AasVhKTsE5DwkSVagBjkg/R7NHH6QHJpYEEruB2oEpafdaInyD5bI4UbixwJdswRbMaIPpGYDsSmBWLoNxBlAQ/ZhLY+QDAwEUqmgFAvEIlBhkDdBqX0+EC8BGoKiESDAAK6/dHcu+XZoAAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: left 18px top;
      font-size: 13px;
      margin-top: 12px;
      line-height: 18px;
      min-height: 18px;
    }
  }
  /**
        erroricon: background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NDBBRjRCNkQ2NDMxMUVBOTZEN0Y1M0MyN0E5REVDNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NDBBRjRCN0Q2NDMxMUVBOTZEN0Y1M0MyN0E5REVDNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ0MEFGNEI0RDY0MzExRUE5NkQ3RjUzQzI3QTlERUM2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ0MEFGNEI1RDY0MzExRUE5NkQ3RjUzQzI3QTlERUM2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+P+9OrAAAAWlJREFUeNqklE0oRGEUhu98xiwodlKyEFlJWSCFDQsxGzZSkpSyVX7KwlKRpYUd8rewQ6KUIotZshgrWyyNhszCXM+ZjrrG/b475dTTmZlz7jvvvfecL+b7vheM9/7OBKkNhqEH6qEcXiENp3BReZl6Cl4XCwoh0kBagglIePYQwVXYQzD/SwgRcbENrV7psQbLiOWMijSSdiwid3AAnyG1BZgrOMr2dcgtbMC05V8H4QqOIBlS/4ABow920mE/i3Vxk7HUK2BGhEb0rdiiTLNx9CSl2Ov9P6pEqC6iyS/KoWEibssLvC2nUBzeoNbRM8p41JDboxylIxzNwjE0RwmdOOo5XZkhuHatjAidw4Ol4RE2maMznXxbbBnd4hXIhzQ0wRTPqIs8bhG5gf24fjmEFlgsapL1WXc4EcfzmHkuTCsfvmSLlUyJQ3gLY1yb+nMe6UnQLbujC1odInAv5xDsIvLy8+O3AAMAZ8hjR5XS3AkAAAAASUVORK5CYII=');
        infoicon: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozODMzREQ3RkQ2NDMxMUVBOERBREY4RTI4NEQ2OEY1QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozODMzREQ4MEQ2NDMxMUVBOERBREY4RTI4NEQ2OEY1QSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjM4MzNERDdERDY0MzExRUE4REFERjhFMjg0RDY4RjVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjM4MzNERDdFRDY0MzExRUE4REFERjhFMjg0RDY4RjVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+r6gHvwAAAYVJREFUeNpi/P//PwM64Np33BFIeQCxNxBrQ4VvAfEOIN75zclyG7oeRmSDgAZIAanJQBzEgB/sBeJcoIHXYQJMSIboAamraIb0ADEfFHchiTsD8RWgHmcUg4ACQkDqCBALoNl8GWjrZxCGWoIMQHr3APUqgzgsUMGlQMyLxQvVQIWmULYFDm+CwkudkXPvMXMg4wQWBb+AmI2BOOAPcl4EFonvQKyFFD4g3IvHoCKQ11ywSPwAhstdtCTxAo9BGiAXKWOR4AZqDAdiJSQxHjwG8YAMYsQiAQqbFUCcQ2QYgaPwER75L0Sa8xVk0H48CsSQ2BJ41D0AGbQKi8RvaKpegSS2Apq6f2NRPxWc14CBehrIMUGS+ACMNUFsVgPVvkfLAa+AasVhKTsE5DwkSVagBjkg/R7NHH6QHJpYEEruB2oEpafdaInyD5bI4UbixwJdswRbMaIPpGYDsSmBWLoNxBlAQ/ZhLY+QDAwEUqmgFAvEIlBhkDdBqX0+EC8BGoKiESDAAK6/dHcu+XZoAAAAAElFTkSuQmCC')
        crossmark: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyRDVBRjJEM0Q2NDMxMUVBOEI0NDk4MzU2N0QwQzA1QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyRDVBRjJENEQ2NDMxMUVBOEI0NDk4MzU2N0QwQzA1QiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjJENUFGMkQxRDY0MzExRUE4QjQ0OTgzNTY3RDBDMDVCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjJENUFGMkQyRDY0MzExRUE4QjQ0OTgzNTY3RDBDMDVCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+hC7q/wAAAf9JREFUeNp0lL1uE1EQhefMXSOTlgIpDRLKC9AQAV2EHQoMRRpEAIHWBW/AK/AINFkFkSDRgVOQpEE8Ag0dDXQUEYgfY5s7h7neNf7bXGlW9+7Ofjsz59gYbFw5O8DoSKFrCj4GpCfjhTJoHrHaq4imIzdNUJhlX86sDFtZhL2DYF0oYsQbVd5y0IHUrZK5adDDtIfE1TgM7zWqrIPTPDP0SHTqGKS0vILD8uAQpdhfXNLG12/bttKcSzYTB0ln3NEEIGg74Hgur9+UxrnvuWp/8FKR3Vv86hhk0oGX6ZVdN+rRYo4ydENzVCizIIjch6EGxJ6399QkHC8BVHIN3KHPQycDg0ktKBJPKqmmAEOurs54MOksfb+GavgR+x4PfPtzRpDZ9duV6HoUnIXKhfTIb2UeDR9d4At/8dkpCu+6BXboeSUdFeSiX39VkMyEgW3f3a2VWGTLMt6UBmXSSgn5U7XDZCS0GJFUWJX6dd6iHLiB53z0f7DJSA5ZUgHEbq38nIIUwVIFN5YBbi8JD9X0kSt3p95HuJ3eVxtmbSPfLgOy3Kt4TjebS/+qXn57HYdZBz82LhNYAGgCaJE+l/osY/wj3qZyb2HYyXj8NKs5VHPXuajTtzLk/ZmBJeN91qCjq2T44MwTQLuiCWBy2l+Bg/Y8EujEAj5qjNf+CTAAe8r2aFrXcJkAAAAASUVORK5CYII=')
        checkmark: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNDhCMTY1N0Q2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNDhCMTY1OEQ2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjI0OEIxNjU1RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjI0OEIxNjU2RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C7PaoQAAASRJREFUeNpi/P//PwM1gNrCjnYgFQvEB4A4jYVKhk4GUjlQbjQQszJS6mKgoVOAVDaa8B8mKrg0G4vURiYKDJ2I5H1ksBOIE5goMDQPi9RuIA6+FV/xhQWqkAtIaQDxVaDgTwoMDQTq/wriMAEVykKTyFmQJJAvSiBM83B4PwhmKNhgIF4CxKZQvi0QbwEaIEYgSSGDHUAcCvI+siDIYAM0hWZAvAlokDgRhu6EGvoZXYJRdUF7JZBuw6LpBBBHAnEuEBcRClMMg0EZBOiiOiC7EYv8WyAWxuHSYFyGwg2GeheX4bgi6hs+RfB0DFTYBKTqCRi6HYhDCBmKYjARhoNiPxw99okyGMnwRizeD8MW+wTDGEu6rYdmhhNQQ7+Sku0BAgwAqNZ6u3oIY6kAAAAASUVORK5CYII=')
    */
  .control-label {
    font-size: 22px;
    line-height: 27px;
    padding-left: 18px;
    padding-bottom: 4px;
  }
  .form-control {
    border: 2px solid #1d6175;
    border-radius: 100vh;
    padding: 12px 18px;
    height: auto;
    &::placeholder {
      text-transform: none;
    }
  }
  .form-group {
    button#toggle-password {
      position:absolute;
    }
    .input-group {
      .form-control {
        border-right:0;
      }
      .input-group-addon {
        background: #fff;
        border-left:0;
        border-top: 2px solid #1d6175;
        border-right: 2px solid #1d6175;
        border-bottom: 2px solid #1d6175;
        border-bottom-right-radius: 100vh;
        border-top-right-radius: 100vh;
        button {
          border:0;
          background: none;
          color:#000;
          font-size: 20px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .form-control.smaller {
    width: 180px;
  }
  .loadingIcon {
    position: absolute;
    right:0;
    top:0;
  }
  .form-control.loading {
    background-position: 0 -100%;    
  }
  .help-block a {
    font-weight: bold;
    color: #ef9008;
    text-decoration: none;
    border-bottom: 2px solid #e7decc;
  }
  .btn {
    font-size: 21px;
    font-weight: 300;
  }
  .btn-arrow {
    padding-right: 80px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBOTBCQTcxM0Q2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBOTBCQTcxNEQ2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE5MEJBNzExRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE5MEJBNzEyRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C+PkNQAAAIpJREFUeNpi/P//PwORgBmIU4H4OBBfJKgaZDCR2PQ/BHwAYjNC6kkxmBmINxJrOCkGgzAbsYaTajDRhpNjMFGGk2swQcMpMRiv4ZQajNNwRiAhC0zO54FYmIE64CMQ6zIx0ArQKigGZeTRJLnRJIPQJEvTpBCiWbFJUkHPSGLVlAbEx4ipmgACDAC79lfsRLUtlQAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: right 15px center;
    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBOTBCQTcxM0Q2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBOTBCQTcxNEQ2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE5MEJBNzExRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE5MEJBNzEyRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C+PkNQAAAIpJREFUeNpi/P//PwORgBmIU4H4OBBfJKgaZDCR2PQ/BHwAYjNC6kkxmBmINxJrOCkGgzAbsYaTajDRhpNjMFGGk2swQcMpMRiv4ZQajNNwRiAhC0zO54FYmIE64CMQ6zIx0ArQKigGZeTRJLnRJIPQJEvTpBCiWbFJUkHPSGLVlAbEx4ipmgACDAC79lfsRLUtlQAAAABJRU5ErkJggg==');
      background-repeat: no-repeat;
      background-position: right 15px center;
    }
    &.back {
      font-size: 21px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDOEM5MDhCNURDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDOEM5MDhCNkRDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkM4QzkwOEIzREM3OTExRUFCOEI3OTJFQjJERUIxNEVFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkM4QzkwOEI0REM3OTExRUFCOEI3OTJFQjJERUIxNEVFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+PfFD9AAAAIxJREFUeNpi/P//PwMRQB+IrYB4FhD/JUYDA8hgAtgMiD/8hwBTItSDMSmGbgRiZmoYjG4oG7GG4jOYIkNxGUyxodgMpoqh6AZTzVBkg6lqKAgzAglZYHK+DMT8DNQBb4HYkImBVoBWQUHzyKNpcqNpBqFplqZpIUTTYpOigp6RhKrJEohnE1s1AQQYANhYV+w3skIyAAAAAElFTkSuQmCC') !important;
    }
  }
  .btn-back {

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplY2VkODg1ZS01MmNlLWI3NDQtYjk2ZC1hMmIyZWExYmVlY2QiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEExRkNEMkFFQUMwMTFFQTlGRkE4MDIyMjY5Q0JGMEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEExRkNEMjlFQUMwMTFFQTlGRkE4MDIyMjY5Q0JGMEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZWNlZDg4NWUtNTJjZS1iNzQ0LWI5NmQtYTJiMmVhMWJlZWNkIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOmVjZWQ4ODVlLTUyY2UtYjc0NC1iOTZkLWEyYjJlYTFiZWVjZCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pn0g1XQAAAC+SURBVHjaYuTce4yBCKAPxFZAPAuI/yJLfHOyxKqBhQhDzYB4FxDzA/EZID5NjEuYSDB0ExCfYyASMJFgaCh6MJBjMDZDfzGQAJhoYSg2g6liKLrBVDMU2WCqGgoCjMAMIgukL0MNpQZ4C8SGTAw0AqCc9xiI3cgNClxZGubiU1DDPwKxHxCvBmI2SlyMHBRUNRw9jKlmOLbIo4rhuFIFxYbjS27YDGemhsHYDDciJR0zEGG4PRBbklLQAwQYAEiLO93ey9ugAAAAAElFTkSuQmCC');
    
    // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDOEM5MDhCNURDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDOEM5MDhCNkRDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkM4QzkwOEIzREM3OTExRUFCOEI3OTJFQjJERUIxNEVFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkM4QzkwOEI0REM3OTExRUFCOEI3OTJFQjJERUIxNEVFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+PfFD9AAAAIxJREFUeNpi/P//PwMRQB+IrYB4FhD/JUYDA8hgAtgMiD/8hwBTItSDMSmGbgRiZmoYjG4oG7GG4jOYIkNxGUyxodgMpoqh6AZTzVBkg6lqKAgzAglZYHK+DMT8DNQBb4HYkImBVoBWQUHzyKNpcqNpBqFplqZpIUTTYpOigp6RhKrJEohnE1s1AQQYANhYV+w3skIyAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: right 11px center;
    background-color: #e4f4f5;
    border: 0;
    overflow: hidden;
    text-indent: -2000px;
    padding: 6px 22px;
    &:hover,
    &:focus,
    &:active {
      // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDOEM5MDhCNURDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDOEM5MDhCNkRDNzkxMUVBQjhCNzkyRUIyREVCMTRFRSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkM4QzkwOEIzREM3OTExRUFCOEI3OTJFQjJERUIxNEVFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkM4QzkwOEI0REM3OTExRUFCOEI3OTJFQjJERUIxNEVFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+PfFD9AAAAIxJREFUeNpi/P//PwMRQB+IrYB4FhD/JUYDA8hgAtgMiD/8hwBTItSDMSmGbgRiZmoYjG4oG7GG4jOYIkNxGUyxodgMpoqh6AZTzVBkg6lqKAgzAglZYHK+DMT8DNQBb4HYkImBVoBWQUHzyKNpcqNpBqFplqZpIUTTYpOigp6RhKrJEohnE1s1AQQYANhYV+w3skIyAAAAAElFTkSuQmCC');
      // background-repeat: no-repeat;
      // background-position: right 15px center;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplY2VkODg1ZS01MmNlLWI3NDQtYjk2ZC1hMmIyZWExYmVlY2QiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEExRkNEMkFFQUMwMTFFQTlGRkE4MDIyMjY5Q0JGMEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEExRkNEMjlFQUMwMTFFQTlGRkE4MDIyMjY5Q0JGMEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZWNlZDg4NWUtNTJjZS1iNzQ0LWI5NmQtYTJiMmVhMWJlZWNkIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOmVjZWQ4ODVlLTUyY2UtYjc0NC1iOTZkLWEyYjJlYTFiZWVjZCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pn0g1XQAAAC+SURBVHjaYuTce4yBCKAPxFZAPAuI/yJLfHOyxKqBhQhDzYB4FxDzA/EZID5NjEuYSDB0ExCfYyASMJFgaCh6MJBjMDZDfzGQAJhoYSg2g6liKLrBVDMU2WCqGgoCjMAMIgukL0MNpQZ4C8SGTAw0AqCc9xiI3cgNClxZGubiU1DDPwKxHxCvBmI2SlyMHBRUNRw9jKlmOLbIo4rhuFIFxYbjS27YDGemhsHYDDciJR0zEGG4PRBbklLQAwQYAEiLO93ey9ugAAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: right 11px center;
      background-color: #bdf2f6;
      // border: 0;
    }
  }
}

@media (max-width: 768px) {
  .onboarding {
    font-size: 17px;
    .canvas {
      padding-bottom: 0;
    }
    h1 {
      font-size: 26px;
    }
    .control-label {
      font-size: 21px;
    }
    .btn {
      font-size: 17px;
    }
  }
}

@media (max-width: 480px) {
  .onboarding {
    font-size: 17px;
    .canvas {
      padding-bottom: 0;
    }
    h1 {
      font-size: 26px;
    }
    .control-label {
      font-size: 21px;
    }
    .form-control {
      font-size: 16px;
    }
    .btn {
      font-size: 13px;
    }
    .btn-arrow {
      padding-right: 50px;
    }
  }
}