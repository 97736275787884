.btn-arrow {
    padding-right: 80px;
    background-color: inherit;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBOTBCQTcxM0Q2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBOTBCQTcxNEQ2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE5MEJBNzExRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE5MEJBNzEyRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C+PkNQAAAIpJREFUeNpi/P//PwORgBmIU4H4OBBfJKgaZDCR2PQ/BHwAYjNC6kkxmBmINxJrOCkGgzAbsYaTajDRhpNjMFGGk2swQcMpMRiv4ZQajNNwRiAhC0zO54FYmIE64CMQ6zIx0ArQKigGZeTRJLnRJIPQJEvTpBCiWbFJUkHPSGLVlAbEx4ipmgACDAC79lfsRLUtlQAAAABJRU5ErkJggg==') !important;
    background-repeat: no-repeat !important;
    background-position: right 15px center !important;
    &:hover {
      background-color: inherit;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBOTBCQTcxM0Q2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBOTBCQTcxNEQ2NTMxMUVBOUMzMEVGQTE2MkYyMkMzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE5MEJBNzExRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE5MEJBNzEyRDY1MzExRUE5QzMwRUZBMTYyRjIyQzNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C+PkNQAAAIpJREFUeNpi/P//PwORgBmIU4H4OBBfJKgaZDCR2PQ/BHwAYjNC6kkxmBmINxJrOCkGgzAbsYaTajDRhpNjMFGGk2swQcMpMRiv4ZQajNNwRiAhC0zO54FYmIE64CMQ6zIx0ArQKigGZeTRJLnRJIPQJEvTpBCiWbFJUkHPSGLVlAbEx4ipmgACDAC79lfsRLUtlQAAAABJRU5ErkJggg==') !important;
      background-repeat: no-repeat !important;
      background-position: right 15px center !important;
    }
}

.admin-view {
  .dataTables_paginate {
    .pagination {
      display:flex;
      justify-content: center;
      margin-top: 20px !important;
      .paginate_button {
        border-radius: 4px;
        margin: 0 2px;
        border: 1px solid #ccc;
        transition: all 0.2s ease-in-out;
        &* {
          background: none;
          border: 0;
        }
        &.active {
          background-color: #ecf2ff;
          a {
            color: #1e5eff;
          }
        }
        &:hover {
          background-color: #ecf2ff;
        }
        &.disabled {
          opacity: 0.4;
          &:hover {
            background: none;
          }
        }
        a {
          color: #5a607f;
          padding: 5px 14px;
          text-decoration: none;
        }
      }
    }
  }
    
  .dataTables_info {
    .seperator {
      display:none;
    }
  }
}