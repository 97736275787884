.radioWrapper {
    &.stacked .flex {
        display: flex;
        .wrapper {
            display: flex;
            flex-direction: column;
        }
    }
    .wrapper {
        display: flex;
        .radio {
            margin:0;
            margin-left: 18px;
            label {
                font-size: 20px;
            }
            .radiocontainer {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                .radiobutton {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #fff;
                    border-radius: 25px;
                    border: 1px solid #1d6175;
                }
                /* On mouse-over, add a grey background color */
                &:hover input ~ .radiobutton {
                    background-color: #efefef;
                }

                /* When the checkbox is checked, add a blue background */
                input:checked ~ .radiobutton {
                    background-color: #25a087;
                    box-shadow: inset 0px 0px 0px 2px #fff;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .radiobutton:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                input:checked ~ .radiobutton:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .radiobutton:after {
                    // left: 9px;
                    // top: 5px;
                    // width: 5px;
                    // height: 10px;
                    // border: solid #25a087;
                    // border-width: 0 3px 3px 0;
                    // -webkit-transform: rotate(45deg);
                    // -ms-transform: rotate(45deg);
                    // transform: rotate(45deg);
                }
            }
        }
    }
}
/** This is an override for the CMS. */
.exportRadios {
    margin-top: 1rem;
    .radio {
        display:block !important;
        margin: 6px 0;
        &:hover,
        label:hover,
        input:hover {
            cursor: pointer;
        }
    }
}