.accordion-container {
  background-color: #fff;
  border: 1px solid #eeeff4;
  border-radius: 12px;
  padding: 30px;

  .accordion-container-filters {
    border-bottom: 1px solid #eeeff4;
    margin-bottom: 25px;
    padding-bottom: 25px;

    * {
      color: #5a6a85;
      font-size: 14px;
      font-weight: 400;
    }

    .filters-years {
      .form-select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
        background-position:  calc(100% - 12px) 0.9em, calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em !important;
        background-size: 10px 10px, 10px 10px, 1px 1.5em !important;
        cursor: pointer;
        max-width: 200px;
      }
    }
    
    .filters-unread-switch {
      text-align: right;
      user-select: none;

      * {
        cursor: pointer;
      }
      
      .form-switch {
        .form-check-input {
          --bs-form-check-bg: #d9e4ff;
          --bs-form-switch-bg: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8' width='8' height='8'%3E%3Cpath style='fill: %23ffffff;' d='m4 7c-1.7 0-3-1.3-3-3 0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3 0 1.7-1.3 3-3 3z'/%3E%3C/svg%3E");
          border: 0;
          transform: scale(2);
          width: 1.7em;
  
          &:focus {
            box-shadow: none;
          }
  
          &:checked {
            background-color: $color__orange;
            
            &:focus {
              box-shadow: 0 0 0 0.1rem rgba(239, 144, 8, 0.5);
            }
          }
        }

        .form-check-label {
          padding-left: 15px;
        }
      }
    }
  }

  .accordion-container-header {
    background-color: #f2f6fa;
    border: 0;
    color: #7c8fac;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
  }
  
  .accordion-item {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0 !important;

    .accordion-header,
    .accordion-body {
      .title,
      .date {
        color: #5a6a85;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .accordion-body {
      p, 
      small {
        color: #131523;
        font-weight: 400;
      }
      p {
        font-size: 16px;
      }
      small {
        font-size: 12px;
        margin-top: 60px;
      }
    }
    .accordion-header {
      .accordion-button {
        border-radius: 0 !important;
        display: inline-block;

        &::after {
          background-size: 18px;
          color: #5a6a85;
          position: absolute;
          top: 22px;
          right: 22px;
        }

        &:focus {
          box-shadow: none;
        }
     
        &:not(.collapsed) {
          background-color: #fff;
          box-shadow: none;

          .title {
            color: #131523;
            font-size: 16px;
            font-weight: 700;
          }
        }

        .new-message-text {
          &:hover {
            background-color: $color__orange;
            color: #fff;
          }
        }
      }
    } 
  }
  
  .empty-message {
    color: #7c8fac;
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
  }

  @include media-breakpoint-down(xl) { 
    .accordion-button {
      .new-message-text {
        letter-spacing: -1px;
      }
    }
  }

  @include media-breakpoint-down(md) { 
    padding: 30px 10px;

    .accordion-header,
    .accordion-container-header {
      padding-right: 0;
      padding-left: 0;

      * {
        font-size: 12px !important 
      }
    }

    .accordion-button {
      padding-right: 0;
      padding-left: 0;

      * {
        font-size: 12px !important 
      }

      &::after {
        top: 20px !important;
        right: 0 !important;
      }
    }

    .accordion-body p {
      font-size: 14px !important;
    }

    .second-word {
      display: none;
    }
  }
}
