.applinkcontainer {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    margin-bottom: 30px;
    & > div {
        display: flex;
        flex-direction: column;
    }
    & > div > div {
        display: flex;
        flex-direction: row;
        margin: 5px -10px;
    }
    a {
        display: block;
        margin: 0 10px;
    }
}
@media (max-width:767px) {
    .applinkcontainer {
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}