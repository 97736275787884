// ----------- New nav styles -----------

$spacer: 1rem !default;
$grid-gutter-width: $spacer * 1.5 !default;

.nav-getdone {
  .navbar {
    border-bottom: 2px solid #d6daec;
  }
}

.topbar,
.nav-getdone {
  @include media-breakpoint-down(md) {
    &.topbar-info {
      font-size: 12px;
      padding: 10px;
    }

    &.topbar-outer {
      padding: 0px 25px 5px;
    }
  }

  ul {
    list-style: none;
  }

  #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;

    &:hover {
      background-color: #eaecf4;
    }
    &:active {
      background-color: #eef2ff;
    }
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }

  .nav-logo {
    margin-right: 50px;

    img,
    svg {
      height: auto;
      max-width: 155px;
    }
  }

  .nav-item {    
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    
    @include media-breakpoint-up(lg) { 
      margin-right: 30px;
      
      &:last-of-type {
        margin-right: 0;
      }
    }

    #alertsDropdown, 
    #messagesDropdown {
      &::after {
        display: none;
      }
    }

    .nav-link {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      padding: 0 15px;
      position: relative;
      height: 34px;
      
      .arrow {
        color: #57bbb7;
        font-size: 12px;
      }  

      &:focus {
        outline: none;
      }
    
      &.show {
        .arrow {
          margin-top: -3px;
          transform: rotate(180deg);
        }
      }

      .badge-counter {
        border-radius: 50%;
        color: #fff;
        margin-top: -16px;
        padding: 0;
        position: absolute;
        right: 0.25rem;
        text-align: center;
        height: 19px;
        width: 19px;

        .badge-number {
          font-size: 12px;
          font-weight: 500;
          line-height: 19px;
        }

        &.badge-alerts {
          background-color: #97e0dc;
        }
        &.badge-messages {
          background-color: #d45151;
        }
      }

      .img-profile {
        height: 2rem;
        width: 2rem;
      }

      &.nav-bijstorten-button {
        background-color: #fff;
        border: 2px solid rgb(237, 143, 33);
        border-radius: 4px;
        color: rgb(237, 143, 33);
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        padding: 0 20px 0 0;
        position: relative;
        height: 34px;
        
        & > img {
          background-color: #e7e8e3;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          margin: 0 15px 0 0;
          padding: 5px 7px;
        }
        
        &:hover {
          background-color: rgb(237, 143, 33, 0.85);
          color: #fff;
        }
      }
    }

    &.active {
      .nav-link {
        background-color: #ed8f21;
        border-radius: 4px;
        color: #fff;
      }
    }

    &:focus {
      outline: none;
    }

    &.nav-item-user {
      .nav-link {
        background-color: #ebf9f9;
        border-radius: 25px;
        margin-left: 5px;
        height: 50px;
      }
    }
  }

  .nav-right {
    height: 50px;

    .nav-item {
      .nav-link {
        height: 50px;
      }

      &.nav-item-alerts,
      &.nav-item-messages {
        .dropdown-menu {
          margin-left: auto;
        }
      }
    }

    .user-avatar {
      background-color: #ed8f21;
      border-radius: 50%;
      margin-right: 10px;
      height: 25px;
      width: 25px;

      .user-avatar-name {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
      }
    }

    .user-name {
      margin-right: 10px;
    }
  }

  .dropdown {
    position: static;

    .dropdown-menu {
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      margin-top: 1px;
      padding: 15px;
      right: calc($grid-gutter-width / 2);
      width: calc(100% - #{$grid-gutter-width});

      &.dropdown-menu-user {
        left: 20px;
        min-width: 200px;
      }

      .dropdown-item {
        border-radius: 5px;
        padding: 10px 15px;
        white-space: normal;

        img {
          margin-left: 10px;
        }
      }

      .icon-menu {
        margin-right: 10px;
        height: auto;
        width: 18px;

        &.icon-account {
          margin-left: -1px;
          width: 20px;
        }
        &.icon-user {
          margin-left: 1px;
          width: 17px;
        }
        &.icon-logout {
          margin-top: -3px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) { 
    .dropdown {
      position: relative;

      .dropdown-menu {
        width: auto;
        right: 0;
      }
    }

    .dropdown-list {
      width: 20rem !important;

      .dropdown-item {
        .text-truncate {
          max-width: 13.375rem;
        }
      }
    }
  }

  .nav-dropdown-button {
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    height: 34px;
    
    &:hover {
      color: #fff;
    }

    &.nav-menu-button {
      background-color: rgb(9, 175, 171);
      padding: 10px 20px 10px 10px;
      z-index: 2;
      
      &:hover {
        background-color: rgb(9, 175, 171, 0.9);
      }
    }

    & + .dropdown-menu {
      min-width: 280px;

      a {
        color: #5a607f;
        font-size: 14px;

        @include media-breakpoint-down(md) {
          font-size: 15px;
        }

        &:hover,
        &:active,
        &.active {
          color: #171717;
        }
        &:active,
        &.active {
          background-color: #eef2ff;
          font-weight: 600;
        }
      }

      .divider {
        border-top: 1px solid #d6daec;
        margin: 10px;
      }
    }
    
    .arrow {
      color: #fff;
      font-size: 12px;
      margin-left: 12px;
    } 
  }

  .no-arrow {
    &::after {
      display: none !important;
    }
  }
}

.navbar-toggler-icon-outer {
  display: block;
  transform: scale(0.6);
  height: 22px;
}

.navbar-toggler-icon-part {
  background: #fff;
  border-radius: 3px;
  display: block;
  margin-bottom: 5px;
  position: relative;
  transform-origin: 4px 0px;
  transition: 
    transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
  height: 4px;
  width: 33px;
  z-index: 1;
}

.navbar-toggler-icon-part:first-child {
  transform-origin: 0% 0%;
}

.navbar-toggler-icon-part:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.nav-menu-button[aria-expanded="true"] .navbar-toggler-icon-part {
  opacity: 1;
  transform: rotate(45deg) translate(-8px, -15px);
}

.nav-menu-button[aria-expanded="true"] .navbar-toggler-icon-part:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.nav-menu-button[aria-expanded="true"] .navbar-toggler-icon-part:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(-4px, 12px);
}

.section-page-nav {
  .page-nav-outer {
    background-color: #fff;
    border: 1px solid #eaedf1;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 30px;
    position: relative;

    &::before {
      content: "";
      background-color: #e6e9f4;
      position: absolute;
      bottom: 28px;
      left: 30px;
      height: 1px;
      width: calc(100% - 60px);
    }
    
    .page-nav-container {
      display: flex;
      position: relative;
      padding: 0;
      width: fit-content;

      .page-nav-item {
        color: #5a607f;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 20px 15px;
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease;
        
        @include media-breakpoint-between(md, lg) {
          font-size: 14px;
          padding: 10px 10px 15px;
        }

        &:hover {
          color: $color__orange;
        }

        &.active {
          color: $color__orange;
          font-weight: 700;
        }
        
        &.selected {
          color: $color__orange;
          font-weight: 700;

          &::before {
            content: "";
            background-color: $color__orange;
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 100%;
          }
        }

        // &:first-of-type {
        //   padding-left: 0;
        // }
      }
      
      .page-nav-underline {
        background-color: $color__orange;
        position: absolute;
        bottom: -2px;
        left: 0;
        transition: width 0.3s ease, left 0.3s ease;
        height: 2px;
        width: 0;
      }
    }
    
    @include media-breakpoint-down(md) {
      padding: 15px;

      &::before {
        display: none;
      }

      .page-nav-container {
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;

        .page-nav-item {
          font-size: 14px;
          padding: 7px 15px !important;
        }

        .page-nav-underline {
          display: none;
        }
      }
    }
  }
}

.nav-mobile {
  display: none;
}

.nav-admin {
  .navbar-nav {
    display: flex;
    flex-direction: row;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

@include media-breakpoint-between(lg, xl) {
  .topbar-inner {
    margin: 0;
    padding: 0;

    .nav-logo {
      margin-right: 25px;

      img {
        max-width: 125px;
      }
    }

    .nav-item {
      .nav-link {
        padding: 0 10px;
      }

      &.nav-item-user {
        .nav-link {
          margin-left: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  body {
    overflow-x: hidden;
  }

  .topbar {
    .topbar-inner {
      padding: 0;
      
      .navbar-top {
        .nav-logo {
          margin: 0;
        }
  
        .nav-left {
          order: 3;
    
          .navbar-collapse  {
            background: #fff;
            border-bottom: 2px solid #f4f4f4;
            margin: 0 -20px;
            opacity: 0;
            padding: 20px;
            position: absolute;
            top: 90px;
            right: 0;
            transform: translate(100%, 0);
            transform-origin: 0% 0%;
            transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
            min-height: 70vh;
            width: calc(100% + 40px);
            z-index: 10;
            
            &.show {
              opacity: 1;
              transform: scale(1.0, 1.0);
            }

            .nav-item {
              flex-direction: column;
            }
          }
        }
        
        .nav-right {
          .navbar-nav {
            flex-direction: row;
            margin-right: 15px;
  
            .nav-link {
              padding: 0 10px 0 0;
            }
          }

          .nav-item-user {
            pointer-events: none;

            .user-avatar {
              margin-left: 5px;
            }

            .arrow-down {
              display: none;
            }
          }
        }
  
        .nav-item-alerts,
        .nav-item-messages {
          .dropdown-menu {
            position: absolute;
            top: 60px;
            width: 50%;
          }
        }
      }
    }
  }

  .nav-desktop {
    display: none;
  }

  .nav-mobile {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  .topbar {
    .topbar-inner {
      .navbar-top {
        .nav-right {          
          .nav-item-user {
            pointer-events: none;

            .nav-link {
              font-weight: 400;
              padding: 0 5px;
              height: 38px;
            }

            .user-name {
              .name {
                display: none;
              }
            }

            .arrow-down {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .topbar {
    &.topbar-outer {
      padding: 0px 15px 5px;
    }

    .topbar-inner {
      .navbar-top {
        .nav-logo {
          img {
            max-width: 120px;
          }
        }

        .nav-left {
          .nav-dropdown-button.nav-menu-button {
            padding: 10px;
        
            .navbar-toggler-icon {
              margin: 0;
            }
            .navbar-toggler-text {
              display: none;
            }
          }
        }
        
        .nav-right {
          .navbar-nav {
            margin-right: 5px;
          }
          
          .nav-item-user {
            .user-name {
              margin-right: 5px;

              .name {
                display: none;
              }
            }

            .user-avatar {
              margin-right: 5px;
              margin-left: 0;
            }
          }

          .nav-item-messages {
            margin-right: 0 !important;
          }
        }
  
        .nav-item-alerts,
        .nav-item-messages {
          .dropdown-menu {
            margin-left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .nav-getdone {
    padding: 0 20px !important;
    
    .nav-getdone-button {
      font-size: 14px;
    }
  }
}

/**
  Made the backend Navigation have its own overrides, to make sure frontend is not impacted
*/
@media (min-width: 768px) {
  .topbar {
    .cms-nav {
      .dropdown-menu{
        width: 20em;
        .dropdown-item {
          white-space: nowrap;
        }
      }
    }
  }
}