table.event-choice-table {
    thead {
      th {
        vertical-align: top;
        border-bottom: 0;
      }
    }
    tbody {
      tr {
        td {
          font-size: 16px;
          padding: 40px 0;
          border: 1px solid #e5e5e5;
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
          .fundname {
            margin-bottom: 10px;
            font-size: 18px;
          }
          .fundbutton {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .progress-wrapper {
              display: flex;
              flex-direction: column;
              width: 40px;
              margin-top: 25px;
              margin-left: 8px;
              text-align: center;
              .progress {
                margin: 0;
              }
              .progress-label {
                font-size: 13px;
              }
              .progress {
                height: 5px;
                .progress-bar {
                  background-color: #e9ac56;
                }
              }
            }
          }
          .choice-wrapper {
            display: inline-flex;
            flex-direction: column;
            padding: 0 30px;
            .pretty {
              label {
                display: inline-flex;
                align-items: flex-start;
              }
              i {
                margin-top: 2px;
                margin-right: 10px;
              }
            }
          }
        }
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
  ul.event-rules-list {
    li {
      margin-bottom: 10px;
    }
  }
  
  .event-management {
    &.event {
      .panel-heading {
        display: flex;
        justify-content: space-between;
      }
      .title {
      }
      .type {
        display: inline-block;
        padding-left: 10px;
        padding-top: 6px;
        font-weight: normal;
        font-size: 12px;
        color: #4b4b4b;
        vertical-align: top;
      }
      .description {
        font-weight: normal;
        font-size: 15px;
        color: #4b4b4b;
        margin-bottom: 40px;
      }
      .dates {
        font-weight: normal;
        font-size: 13px;
        color: #4b4b4b;
        padding-top: 4px;
      }
      .status {
        font-weight: normal;
        font-size: 13px;
        text-align: right;
        &.status1 {
          color: green;
        }
        &.status0 {
          color: red;
        }
      }
      table {
        th {
          border-top: none;
        }
        tr {
          td {
            vertical-align: middle;
          }
        }
      }
    }
  }
  
  @media (max-width: 767px) { 
  
      table.event-choice-table {
          td {
              padding: 0 !important;
              display:block !important;
              border:none !important;
              text-align: left !important;
              div {
                  text-align: left !important;
              }
              .fundname {
                  font-size: 22px;
                  font-weight: bold;
              }
          }
          tr {
              td:last-child {
                  margin-bottom: 40px;
              }
          }
      }
      .eventheading {
        display:block;
          .btn {
              display: block;
              margin: auto;
              float: none;
          }
      }
      .event-dividend-submit {
          float:left !important;
      }
  }

.fundSelector {
  display: flex;
  justify-content: center;
  margin: 0 -30px 0 -30px;
  padding: 0 17px 30px 17px;
  position: relative;
  flex-wrap: wrap;
  &::before {
    content: " ";
    background: #cef3f4;
    position:absolute;
    top: 100px;
    width:100%;
    bottom:0;
    left:0;
    right:0;
    display: none;
  }
  > div {
    border-radius: 8px;
    max-width: 232px;
    text-align:center;
    margin: 0 10px 30px 10px;
    background: #fff;
    z-index: 10;
    position: relative;
    padding-bottom: 30px;

    .absolutelink {
      position:absolute;
      display:block;
      left:0;
      top:0;
      width: 100%;
      height: 100%;
    }
    img {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      max-width:100%;
      height:auto;
    }
    .title {
      padding: 10px 0;
      font-size: 13px;
      color:#fff;
      font-weight:bold;
    }
    .body {
      padding: 15px;
      font-size: 13px;
      span {
        display:block;
        min-height: 60px;
      }
    }
  }
  .fund_id_1 {
    .title {
      background: #1e5489;
    }
  }
  .fund_id_2 {
    .title {
      background: #1e5489;
    }
  }
  .fund_id_3 {
    .title {
      background: #3e9f87;
    }
  }
  .fund_id_6 {
    .title {
      background: #f6a400;
    }
  }
}
