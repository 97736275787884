// ----------- Old nav styles -----------

.navbar {
  margin-bottom: 0 !important;
}

.navbar-wrapper {
  background: #eeb013;
  background: #cdf1f3;
  // -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
  // -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
  // box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
}

.navbar-main {
  min-height: 0;
  background: #cdf1f3;
  padding: 40px 0px 0 0px;
  border-bottom: 0;

  .nav-top {
    border-bottom: 0;
    margin-left: 40px;
    margin-right: 40px;
    
    & > li > a {
      border-radius: 0;
      color: #000000;
      font-weight: 700;
      font-size: 18px;
      border: none;
      padding: 10px 20px;
      text-decoration:none;
    }

    & > li > a:hover {
      cursor: pointer;
    }

    & > li.active > a,
    & > li.active > a:hover,
    & > li.active > a:focus,
    & > li > a:hover,
    & > li > a:focus {
      border-radius: 0;
      color: #eeb325;
      background: #fff;
      border: none;
    }
  }

  .nav-bottom {
    // position:absolute;
    // left:0;
    // top:100%;
    margin-top: 1px;
    border-radius: 0;
    background: #fff;
    padding: 0px 40px;

    &.nav > li > a {
      border-radius: 0;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 16px;
      padding: 18px 25px;
      text-decoration:none;
    }

    &.nav > li.active > a,
    &.nav > li.active > a:hover,
    &.nav > li.active > a:focus,
    &.nav > li > a:hover,
    &.nav > li > a:focus {
      border-radius: 0;
      color: #eeb325;
      font-weight: 700;
      background: #fff;
    }
  }
}

