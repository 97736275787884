// @import url(https://code.highcharts.com/css/highcharts.css);
.highcharts-tooltip {
    font-size: 1.4em;
    line-height: 18px;
    stroke: none;
    width:10rem;
    
    > span {
        font-size: 12px;
        .seriesValue {
            font-weight: 700;
            font-size: 12px;
            &.seriesRendement {
                color: #0bbfb8;
            }
            &.seriesWaarde {
                color: #000;
            }
            &.seriesStortingen {
                color: #0bbfb8;
            }
            &.seriesOnttrekkingen {
                color: #1e5489;
            }
        }
    }
}
#graphSelector {
    .dropdown-toggle {
        display:flex;
        border: 1px solid #d9e1ec !important;
        width: 100%;
        padding: 8px 12px;
        .bi {
            margin-left: auto;
        }
        &:after {
            display:none;
        }
        &:hover {
            background: none;
            color:inherit;
        }
        &.show {
            background-color: #fff;
            color: inherit;
        }
        .dropdown-item:focus, 
        .dropdown-item:hover {
            background: #ecf9f9;
        }
    }
    .dropdown-menu {
        border-radius: 0;
        padding:0;
        width:100%;
        border-color: transparent;
        &.show {
            background-color: #fff;
        }
        .dropdown-item {
            padding: 8px 12px;
            font-size: 0.8rem;
            &:hover {
                text-decoration:none;
            }
            &.active {
                background-color: #ecf9f9;
                color: inherit;
            }
        }
    }
}
#buttonContainer {
    font-size: 0.8rem;
    color: #5a607f;
    button {
        color: #5a607f;
        background-color: rgba(170, 233, 230, 0.69);
        padding: 6px 12px;
        &.active {
            background-color: rgba(170, 233, 230, 1);
        }
    }
    .dropdown-toggle {
        color: #5a607f;
        background: #eaf6f6;
        border-color: #cef6f4 !important; 
        padding: 6px 12px;
    }
    @media (min-width: 1024px) { 
        .dropdown-menu {
            display:block;
            border:0;
            padding:0;
            margin:0;
            position:relative;
            width:100%;
            .dropdown-item {
                display:inline-block;
                padding:0;
                margin:0;
                width:auto;
                background: none;
                &:active {
                    background: none;
                }
            }
        }
    }
    @media (max-width: 1024px) { 
        .dropdown-menu {
            .dropdown-item {
                padding:0;
                .badge {
                    background: none;
                    color: #5a607f;
                    padding:12px;
                    margin:0;
                    border:none;
                    font-weight: normal;
                    width:100%;
                    border-radius: 0;
                    text-align:left;
                    &.active {
                        background-color: rgba(170, 233, 230, 1);
                    }
                }
            }
        }
    }
}

.highcharts-tooltip-box {
  // fill: #e9f4f3;
  // stroke: #b3e8e5;
  fill: transparent;
  stroke: transparent;
}

.highcharts-tooltip {
  background-color: #e9f4f3;
  
  .tooltip-date {
    background-color: #e9f4f3;
    padding: 10px 10px 3px;
  }
  
  .tooltip-text {
    background-color: #e9f4f3;
    padding: 0 10px 3px;

    &:last-child {
      padding-bottom: 10px;
    }
  }
}