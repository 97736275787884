.box {
  border-radius: 12px;
  padding: 25px 30px;

  a {
    text-decoration: none;
  }

  .box-heading {
    color: #2a3547;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    position: relative;
  }

  .menu-dots {
    position: absolute;
    top: 0;
    right: -6px;

    i::before {
      color: #7c8fac;
    }
  }

  &.box-white {
    background-color: #fff;
    border: 1px solid #eaedf1;
  }

  &.box-banner-image {
    padding: 0;

    img {
      border-radius: 12px;
      height: auto;
      max-width: 100%;
    }
  }

  &.box-total-current-value {
    margin-bottom: 35px;
    padding: 20px 30px;

    .box-heading {
      margin-bottom: 10px;
    }

    .row-top {
      .amount {
        // display: flex;
        display: block;
        align-items: center;
        margin-bottom: 5px;

        .amount-number {
          color: #2a3547;
          font-size: 36px;
          font-weight: 800;
        }

        .amount-number-tooltip {
          margin-left: 15px;
        }
      }

      .amount-desc {
        span {
          color: #7c8fac;
          display: block;
          font-size: 10px;
        }
      }

      .percentage {
        font-size: 14px;
        font-weight: 500;
        padding-top: 12px;
        text-align: right;

        &.up {
          color: $color__green;
        }
        &.down {
          color: $color__red;
        }

        i {
          -webkit-text-stroke: 1px;
        }
      }

      @include media-breakpoint-down(sm) {
        .amount {
          padding-left: 0;
        }
        .percentage {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    .row-bottom {
      border-top: 1px solid #eceef6;
      margin-top: 25px;
      margin-right: 0;
      margin-left: 0;
      padding-top: 20px;

      .amount {
        color: #686868;
        font-size: 16px;
        font-weight: 500;
        padding: 0;
      }
      .desc {
        color: #7c8fac;
        font-size: 10px;
        padding: 0;
        text-align: right;
      }
    }

    &.box-total-current-value-funds {
      @include media-breakpoint-up(lg) {
        min-height: 291px;
      }
      
      .box-content {
        .row-bottom {
          margin-top: 15px;
          padding-top: 15px;

          .text, 
          .amount {
            padding-right: 0;
            padding-left: 0;
          }
          .text {
            font-weight: 500;
          }
        }
      }
    }
  }

  &.box-monthly-investments {
    margin-bottom: 35px;
    
    @include media-breakpoint-up(lg) {
      min-height: 271px;
    }
    
    .box-content {
      .row-fund {
        border-bottom: 1px solid #d7dbec;
        margin: 0 0 15px 0;
        padding-bottom: 15px;
        
        &:last-of-type {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        > div {
          padding-right: 0;
          padding-left: 0;

          @include media-breakpoint-down(sm) {
            font-size: 14px;
            padding: 0 !important;
          }
        }

        .col-code {
          font-weight: 600;

          .color-green {
            color: $color__green;
          }
          .color-orange {
            color: $color__orange;
          }
          .color-dark_blue {
            color: $color__dark_blue;
          }
        }

        .col-amount {
          font-weight: 600;
          padding-left: 30px;
          white-space: nowrap;
        }

        .col-date {
          text-align: right;
        }

        .col-empty {
          margin-bottom: 30px;
        }
      }
    }

    .box-button {
      border-top: 1px solid #d7dbec;
      padding-top: 20px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    padding: 20px 25px;
  }
}