.page-login {
  form input, 
  form select {
    max-width: 100%;
  }

  .buttonBox {
    text-align: center;
  }

  .cant-login {
    font-size: 14px;
  }
}

.page-password-reset, 
.page-username-request {
  .form-group {
    margin-bottom: 5px;
  }

  form input, form select {
    max-width: 100% !important;
  }

  .buttons-container {
    display: flex;
    margin-top: 10px;

    .btn {
      margin: 0;

      &.btn-primary {
        margin: 0 0 0 auto;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;

      .btn {
        display: block;
        width: 100%;
  
        &.btn-warning {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
}