.widgets {
  .whatsapp-widget-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99999;
  }
  .whatsapp-widget-button .whatsapp-widget-icon {
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    height: auto;
    width: 80px;
  }
  .whatsapp-widget-button:hover .whatsapp-widget-icon {
    opacity: 0.75;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  @media (max-width: 767.98px) {
    .whatsapp-widget-button .whatsapp-widget-icon {
      width: 70px;
    }
  }
  @media (max-width: 575.98px) {
    .whatsapp-widget-button {
      right: 5px;
      bottom: 5px;
    }
    .whatsapp-widget-button .whatsapp-widget-icon {
      width: 60px;
    }
  }
}