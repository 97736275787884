.section-breadcrumbs {
  .breadcrumbs-inner {
    background-color: #ebf3fe;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 30px;
    
    .breadcrumbs-header {
      color: #2a3547;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .breadcrumbs-content {
      display: flex;
      margin-bottom: 5px;
      padding-left: 0;
      
      .breadcrumbs-item {
        color: #7c8fac;
        margin-left: 27px;
        position: relative;
        
        a,
        span {
          color: inherit;
          font-size: 14px;
          letter-spacing: -0.5px;
          text-decoration: none;
        }

        a {
          &:hover {
            color: #2a3547;
          }
        }
        span {
          color: #2a3547;
        }
        
        &:first-of-type {
          list-style: none;
          margin-left: 0;
        }
      }
    }
  } 

  @include media-breakpoint-down(md) {
    display: none;
  }
}