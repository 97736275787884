form {
  .form-file-upload {
    .control-label {
      color: #5a607f;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;
    }
  
    .alert {
      font-size: 12px;  
    }
  
    .btn-outline-gray {
      border: 1px solid #d7dbec !important;
      color: #7c8cc5;
    }
  
    .upload-dropzone {
      border: 2px dashed rgba(161, 167, 196, .5);
      padding: 40px 20px 45px;
      border-radius: 5px;
      cursor: pointer;
      transition: border-color 0.3s;
      
      &.dragover {
        border-color: $color__orange;
      }
    }
  
    .upload-message-container {
      .upload-message {
        border: 1px solid;
        border-radius: 4px;
        font-size: 14px;
        padding: 10px 15px;
        position: relative;
  
        &::before {
          font-family: "bootstrap-icons";
          font-size: 14px;
          margin-right: 10px;
          position: relative;
          top: 2px;
          left: 0;
        }
    
        &.upload-error {
          background: rgba(240, 20, 47, .1);
          border-color: $color__red;
          color: $color__red;
          
          &::before {
            content: "\f622";
          }
        }
      
        &.upload-success {
          background: rgba(215, 219, 236, .1);
          border-color: #d7dbec;
          color: #7c8cc5;
        }

        .remove-file {
          cursor: pointer;
          margin-right: 10px;
        }
      }
      
    }
  }
}
