.pagination {
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 20px;
  padding-right: 38px;
  padding-left: 38px;

  .page-item {
    border-radius: 4px;
    margin: 0 2px 10px;
    transition: all .2s ease-in-out;

    &.page-item-nav {
      &:first-of-type {
        margin-left: -38px;
      }
      &:last-of-type {
        margin-right: -38px;
      }
    }

    * {
      background: none;
      border: 0;
    }

    .page-link {
      color: #5a607f;
      margin-top: 0;
      margin-bottom: 0;
      padding: 5px 14px;
      text-decoration: none;
    }

    &.page-item-nav {
      font-family: "bootstrap-icons";
      line-height: 1.2rem;

      .page-link {
        padding: 7px 10px;
      }
    }

    &.active {
      background-color: #ecf2ff;

      .page-link {
        color: #5a607f;
      }
    }

    &:hover {
      background-color: #ecf2ff;
    }
    
    &.disabled {
      opacity: .4;
      
      &:hover {
        background: none;
      }
    }
  }
}

.dataTables_wrapper {
  .table {
    .btn {
      min-width: 80px;
    }
  }
  
  .dataTables_info {
    display: none;
  }

  .dataTables_paginate {
    .pagination {
      justify-content: start;
      margin: 30px 0 10px !important;
  
      .paginate_button {
        border-radius: 4px;
        margin: 0 2px;
        transition: all .2s ease-in-out;
  
        * {
          background: none;
          border: 0;
        }
  
        a {
          color: #5a607f;
          display: block;
          padding: 5px 14px;
          text-decoration: none;
          height: 100%;
        }
  
        &.previous, 
        &.next {
          font-family: "bootstrap-icons";
          line-height: 1.2rem;
          
          a {
            display: block;
            font-size: 0;
            padding: 0;
            position: relative;
            height: 34px;
            width: 34px;
  
            &::before {
              font-size: 16px;
              line-height: 34px;
              position: absolute;
              top: 0;
              left: 0;
              text-align: center;
              height: 34px;
              width: 34px;
            }
          }
        }
        &.previous {
          a {
            &::before {
              content: "\F12F";
            }
          }
        }
        &.next {
          a {
            &::before {
              content: "\F138";
            }
          }
        }
  
        &.active {
          background-color: #ecf2ff;
  
          .page-link {
            color: #5a607f;
          }
        }
  
        &:hover {
          background-color: #ecf2ff;
        }
        
        &.disabled {
          opacity: .4;
          
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}

.page-database {
  .table thead tr th {
    font-size: 15px;
  }
  .dt-info {
    margin-top: 10px;
  }
  .pagination {
    justify-content: end;
    margin-top: -10px !important;
  }
}