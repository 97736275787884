.table-container {
  background-color: #fff;
  border: 1px solid #eeeff4;
  border-radius: 12px;
  padding: 30px;
  
  .table-heading {
    border-bottom: 1px solid #d9d9d9;
    color: #5a607f;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
    padding-bottom: 25px;
  }

  .table-container-filters {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 30px;
    padding-bottom: 20px;
 
    .filters-date {
      .filters-date-col {
        color: #5a6a85;
        display: inline-flex;
        font-size: 14px;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .filters-date-datepicker {
        border-radius: 4px;
        text-align: center;
        max-width: 160px;

        * {
          cursor: pointer;
        }

        input {
          border: 1px solid #d9e1ec;
          border-radius: 4px !important;
        }

        .input-group-addon {
          display: none;
        }
        
        .form-control {
          color: #5a6a85;
          font-size: 14px;
        }
      }

      .filters-date-submit {
        margin-top: 10px;

        .btn {
          background-color: #7b8bc5;
          border: 1px solid #7b8bc5;
          color: #fff;
          margin-top: -3px;
          padding: 7px 15px;

          &:hover {
            opacity: .9;
          }
        }
      }
    }

    .filters-download {
      a {
        color: #5a607f;
        font-size: 12px;
        font-weight: 400;
        text-decoration: none;

        i {
          color: #ff1d12;
          font-size: 15px;
          margin-right: 4px;
        }

        &:hover {
          color: #ff1d12;
        }
      }
    }

    @include media-breakpoint-down(md) { 
      .filters-date {
        form {
          display: flex;
          flex-direction: column;
  
          .filters-date-col {
            margin: 10px 0;
            max-width: 100%;

            .form-group {
              width: 100%;
            }
          }

          .filters-date-submit {
            margin-top: 15px;

            .form-group {
              margin: 0 auto;
            }
          }
        }
      }

      .filters-download {
        justify-content: center !important;
        margin: 0 auto !important;
      }
    }
  }
    
  .table, 
  .table-responsive {
    thead {
      tr th {
        background-color: #f2f6fa;
        border: 0;
        color: #7c8fac;
        font-size: 14px;
        font-weight: 600;
        padding-top: .75rem;
        padding-bottom: .75rem;
      }
    }
    tbody {
      tr td {
        background-color: #fff;
        color: #5a6a85;
        font-size: 14px;
        font-weight: 400;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
    }

    &.table-hover > tbody > tr:hover > * {
      --bs-table-bg-state: #f2f6fa;
    }

    &.table-incassos,
    &.table-transactions {
      tr {
        th, 
        td {
          &:last-of-type {
            border-left: 1px solid #dee2e6;
          }
        }
      }
    }

    &.table-incassos {
      tr {
        th, 
        td {
          &:last-of-type {
            padding-left: 20px;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      th, tr {
        min-width: 100px;
      }
    }
  }

  .empty-message {
    color: #7c8fac;
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
  }

  .dt-info, 
  .dt-length {
    color: #5a6a85;
    font-size: 14px;
  }
}

.table-admin-documenten {
  .table thead tr th, 
  .table-responsive thead tr th {
    &:nth-of-type(2) {
      width: 26%;
    }
    &:nth-of-type(4) {
      width: 30%;
    }
    &:nth-of-type(5) {
      width: 14%;
    }
    &:nth-of-type(6) {
      width: 11%;
    }
    &:nth-of-type(7) {
      width: 14%;
    }
  }
}