/**
https://nigelotoole.github.io/progress-tracker/
*/

// Colours
$color-marker: #e6e6e6 !default;
$color-marker-active: #cff1f3 !default;
$color-marker-complete: #cff1f3 !default;
$color-marker-hover: #56adf5 !default;

$color-path: #e6e6e6 !default;
$color-path-complete: #cff1f3 !default;

$color-text: #333 !default;
$color-marker-text: #000000 !default;

// Sizing
$marker-size: 28px !default;
$marker-size-half: ceil(calc($marker-size / 2));
$marker-size-third: ceil(calc($marker-size / 3));
$marker-size-quarter: ceil(calc($marker-size / 4));
$marker-spacing: 8px !default;

$marker-font-size: 12px;

$path-height: 2px !default;
$path-position: $marker-size-half - calc($path-height / 2);

$text-padding: 20px !default;
$text-padding-X: $marker-size-third !default;
$text-padding-Y: $text-padding !default;
$text-padding--vertical: $text-padding * 1.5 !default;

$progress-tracker-padding: 20px !default;

// Dots connecting text to markers
$dot-size: 12px;
$dot-size-half: calc($dot-size/2);
$dot-spacing: 6px;
$dot-levels: 12;

$color-dot: $color-path;

// Animations/Transitions
$animation-duration: 0.3s !default;
$ripple-color: rgba(0, 0, 0, 0.3) !default;
// ----- Progress Tracker Animations -----
// Animation show after click using pure CSS based on a technique - https://ghinda.net/article/css-ripple-material-design/ and the Material design ripple - https://getmdl.io/components/#buttons-section

// Step state mixin - The first argument is required and the rest are optional, if you pass in null the value will not be changed.
@mixin progress-state($color-marker, $color-path: null, $color-text: null, $color-marker-text: null, $color-marker-border: null) {
    .progress-marker {
      color: $color-marker-text;
  
      &::before {
        background-color: $color-marker;
        border-color: $color-marker-border;
      }
  
      &::after {
        background-color: $color-path;
      }
    }
  
    .progress-text {
      color: $color-text;
    }
  }

// Ripple elements mixin
@mixin ripple-element() {
    content: "";
    display: block;
    width: $marker-size;
    height: $marker-size;
    position: absolute;
    top: $marker-size-half;
    left: $marker-size-half;
    z-index: 30;
    background: $ripple-color;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0); // Initial state, hides the effect when not animating
    visibility: hidden; // Hides element so it does not animate on page load
  }
  
  @mixin ripple-element-active() {
    visibility: visible; // Shows element when it is focused
  }
  
  // Animation Keyframes
  @keyframes scale-up {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0);
    }
  
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  
  
  // ----- Ripple effect when marker is clicked -----
  .anim-ripple, .anim-ripple-large, .anim-ripple-splash {
  
    .progress-link::before {
      @include ripple-element();
    }
  
    .progress-link:not(:active)::before {
      animation: scale-up $animation-duration ease-out;
    }
  
    .progress-link:focus::before {
      @include ripple-element-active();
    }
  
  }
  
  .anim-ripple, .anim-ripple-large, .anim-ripple-splash, .anim-ripple-double {
  
    &.progress-tracker--center, .progress-tracker--center & {
  
      .progress-link {
        &::before, &::after {
          left: 50%;
        }
      } 
    }
  
    &.progress-tracker--right, .progress-tracker--right & {
  
      .progress-link {
        &::before, &::after {
          left: calc(100% - #{$marker-size-half});
        }
      }
  
    }
  
  }
  
  
  // ----- Large ripple with splash -----
  .anim-ripple-splash {
  
    .progress-link::before {
      width: $marker-size*2;
      height: $marker-size*2;
      box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
    }
  
  }
  
  // ----- Double ripple effect, similar to an actual water ripple -----
  .anim-ripple-double {
  
    .progress-link {
      &::before, &::after {
        @include ripple-element();
        background: none;
        border: 3px solid $ripple-color;
      }
    }
  
    .progress-link:not(:active)  {
      &::before, &::after {
        animation: scale-up $animation-duration ease-out 0s;
      }
      
      &::after {
        animation-delay: calc($animation-duration/2);
      }
    }
  
    .progress-link:focus  {
      &::before, &::after {
        @include ripple-element-active();
      }
    }
  
  }
  
  .anim--large {
    .progress-link {
      &::before, &::after {
        width: $marker-size*2;
        height: $marker-size*2;
      }
    }
  }
  
  
  // ----- Fill up the path between markers when it becomes active -----
  .anim--path {
  
    .progress-marker {
  
      &::after {
        background-image: linear-gradient(to right, $color-path 50%, $color-path-complete 50%);
        background-size: 200% 100%;
        background-position: 0% 100%;
        transition: background-position $animation-duration ease-out;
      }
  
      .progress-step.is-complete &::after {
        background-position: -100% 100%;
      }
  
    }
  
  
  
      .progress-step.is-complete .progress-marker::after {
        background-position: -100% 100%;
      }
  
  
  }
  

// Markers with text
.progress-tracker--text, .progress-tracker--center, .progress-tracker--right {

    .progress-step {
      &:last-child {
        flex-grow: 1;
      }
    }
  }
  
  
  // Center align markers and text
  .progress-tracker--center {
    text-align: center; 
  
      .progress-marker, .progress-text--dotted {
        &::before {
          margin-left: auto;
          margin-right: auto;
          font-size: $marker-font-size;
          padding-top: 3px;
        }
      }
  
      .progress-marker {
        &::after {
          right: -50%;
        }
      }
  }
  
  
  // Right align markers and text
  .progress-tracker--right {
    text-align: right;
  
      .progress-marker, .progress-text--dotted {
        &::before {
          margin-left: auto;
        }
      }
  
      .progress-marker {
        &::after {
          right: calc(-100% + #{$marker-size-half});
        }
      }  
  }
  
  
  // Spaces between markers
  .progress-tracker--spaced {
  
    .progress-marker {
      &::after {
        width: calc(100% - #{$marker-size + ($marker-spacing * 2)});
        margin-left: ($marker-size-half + $marker-spacing);
        margin-right: ($marker-size-half + $marker-spacing);
      }
    }
  }
  
  
  // Border around tracker
  .progress-tracker--border {
    padding: $progress-tracker-padding;
    border: 2px solid $color-text;
    border-radius: $marker-size + ($progress-tracker-padding * 2);
  }
  
  
  // Color theme
  .progress-tracker--theme-red {
    .progress-step {
      
      // Inactive - Default state
      @include progress-state(#666, $color-path: #666, $color-text: $color-text, $color-marker-text: $color-marker-text);
  
      // Active state
      &.is-active {
        @include progress-state(#A62D24);
      }
  
      // Complete state
      &.is-complete {
        @include progress-state(#D93B30, $color-path: #333);
      }
  
      // Hover state
      &:hover {
        @include progress-state(#DF7B74);
      }
    }
  }
  
  
  // Dots connecting markers to the text
  .progress-text--dotted { 
  
    &::before {
      content: '';
      display: block;
      width: $dot-size;
      height: $dot-size;
      margin: $dot-spacing #{ -$text-padding-X + ($marker-size-half - $dot-size-half) };
      background-size: $dot-size ($dot-size + $dot-spacing);
      background-image: repeating-radial-gradient(circle at center $dot-size-half, 
      $color-dot,
      $color-dot ($dot-size-half - 1px), 
      rgba($color-dot, .5) ($dot-size-half - .5px), 
      rgba($color-dot, .01) $dot-size-half,
      transparent 100%);
    }
  }
  
  @for $i from 1 through $dot-levels {
    .progress-text--dotted-#{$i} {
      &::before {
        height: (($dot-size + $dot-spacing) * $i) - $dot-spacing;
      }
    }
  }
  
  
  // Text above markers
  .progress-tracker--text-top {
  
      .progress-text {
        height: 100%;
      }
  
      .progress-marker {
        top: -#{$marker-size};
      }
  }
  
  
  // Text inline with markers
  .progress-tracker--text-inline {
    overflow: hidden;
  
    .progress-step, .progress-marker {
      display: flex;
      align-items: center;
    }
  
      .progress-marker {
        flex-grow: 1;
  
        &::after {
          top: auto;
        }
      }
  
      .progress-text {
        position: relative;
        z-index: 30;
        max-width: 70%;
        white-space: nowrap;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff;
      }
  
      .progress-marker .progress-text {
        display: inline-block;
      }
  
        .progress-title {
          margin: 0;
        }
  }
  
  
  // Square markers
  .progress-tracker--square {
  
      .progress-marker {
        &::before {
          border-radius: 0;
        }
  
        &::after {
          top: auto;
          bottom: 0;
        }
      }
  }
  
  
  // Overflow on small screens
  @media (max-width: 575px) {
    .progress-tracker-wrapper {
      overflow-x: auto;
      scroll-snap-type: x proximity;
  
      .progress-step {
        min-width: 50%;
        scroll-snap-align: start;
      }
    }
  }
  
  
  // Vertical layout
  .progress-tracker--vertical {
    flex-direction: column;
  
    .progress-step {
      display: flex;
      flex: 1 1 auto;
    }
  
    &.progress-tracker--right .progress-step {
      flex-direction: row-reverse;
    }
  
      .progress-marker {
        &::after {
          right: auto;
          top: $marker-size-half;
          left: $path-position;
          width: $path-height;
          height: 100%;
        }
      }
  
      .progress-text {
        padding: 0 $text-padding--vertical $text-padding--vertical*2 $text-padding--vertical;
      }
  }
  

// Container element
.progress-tracker {
  display: flex;
  padding: 0;
  list-style: none;
}

// Step container
.progress-step {
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: $marker-size; // For a flexbox bug in firefox that wont allow the text overflow on the text
  text-decoration: none !important;
  // Stops the last step growing
  &:last-child {
    flex-grow: 0;

    .progress-marker::after {
      display: none;
    }
  }
}

// Link wrapper for the marker and text
.progress-link {
  display: block;
  position: relative;
}

// Progress marker
.progress-marker {
  display: block;
  position: relative;

  // Marker
  &::before {
    content: attr(data-text);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 20;
    width: $marker-size;
    height: $marker-size;
    padding-bottom: 2px; // To align text within the marker
    border-radius: 50%;
    transition: background-color, border-color;
    transition-duration: $animation-duration;
  }

  // Path between markers
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: $path-position;
    right: -#{$marker-size-half};
    width: 100%;
    height: $path-height;
    transition: background-color $animation-duration,
      background-position $animation-duration;
  }
}

// Progress text
.progress-text {
  display: block;
  padding: $text-padding-Y $text-padding-X;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-title {
  margin-top: 0;
}

// ----- States -----
.progress-step {
  // Inactive - Default state
  @include progress-state(
    $color-marker,
    $color-path: $color-path,
    $color-text: $color-text,
    $color-marker-text: $color-marker-text
  );

  // Active state
  &.is-active {
    @include progress-state($color-marker-active);
  }

  // Complete state
  &.is-complete,
  &.is-progress {
    @include progress-state(
      $color-marker-complete,
      $color-path: $color-path-complete
    );
  }

  // In progress state
  @for $i from 1 through 9 {
    &.is-progress-#{$i*10} {
      .progress-marker::after {
        background-image: linear-gradient(
          to right,
          $color-path-complete $i * 10%,
          $color-path $i * 10%
        );
      }
    }
  }

  // Hover state
  &:hover {
    @include progress-state($color-marker-hover);
  }
}
