.box-funds-inline-outer {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .box-content-row {
    align-items: center;
    justify-content: flex-end;
  }

  .box-funds-inline {
    .title {
      color: #2a3447;
      font-size: 16px; 
      font-weight: 700;
      margin-bottom: 10px;
    }

    .number {
      font-size: 20px;
      font-weight: 700;
      margin: 0 15px 0 0;
    }

    .desc {
      color: #5a607f;
      font-size: 12px;
      font-weight: 300;
    }

    .col-right {
      .sub-cols {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    @media (max-width: 768px) {
      .col-right {
        .sub-cols {          
          .button {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.5px;
            padding: 5px 4px;
          }
        }
      }
    }

    @media (max-width: 575px) {
      padding: 15px 10px;
      
      .col-right {
        .sub-cols {
          align-content: flex-end;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: right;
          
          .number {
            margin: 10px 0 0 0;
            order: 2;
          }
          .image-wrapper {
            order: 1;
          }
        }
      }
    }
  }
}

.box-funds-inline-alt-outer {
  .box-funds-inline-alt {
    padding: 0;

    .row-funds {
      margin-right: 0;
      margin-left: 0;

      .col-fund {
        box-shadow: none;
        padding: 10px;
        position: relative;
        transition: box-shadow 0.3s ease;

        &::after {
          content: ''; 
          background-color: #d7dbec; 
          position: absolute; 
          top: 50%; 
          right: 0; 
          transform: translateY(-50%); 
          height: 85%; 
          width: 1px; 
        }

        .box-content {
          padding: 10px 0;
          position: relative;

          p {
            padding: 0;
          }

          .box-content-row {
            align-items: center;
            padding: 0 10px;

            .title {
              color: #2a3447;
              font-size: 16px; 
              font-weight: 700;
              margin-bottom: 10px;
            }

            .number {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 15px;
            }
            .desc {
              color: #5a607f;
              font-size: 12px;
              font-weight: 300;
              margin-bottom: 15px;
            }
            .percentage {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 0;

              &.up {
                color: $color__green;
              }
              &.down {
                color: $color__red;
              }

              i {
                margin-left: 4px;
                -webkit-text-stroke: 1px;
              }
            }
          }
        }

        &:first-of-type {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:nth-child(4n) {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;

          &::after {
            display: none;
          }
        }
        &:nth-child(n + 5) {
          .box-content {
            &::before {
              content: "";
              border-top: 1px solid #d7dbec;
              position: absolute;
              top: -10px;
              left: 0;
              height: 1px;
              width: 100%;
            }
          }
        }

        @include media-breakpoint-down(xl) {
          &:nth-child(2n) {
            &::after {
              display: none;
            }
          }
          &:nth-child(n + 3) {
            .box-content {
              &::before {
                content: "";
                border-top: 1px solid #d7dbec;
                position: absolute;
                top: -10px;
                left: 0;
                height: 1px;
                width: 100%;
              }
            }
          }
        }

        @include media-breakpoint-down(sm) {
          &::after {
            display: none;
          }

          .box-content {
            &::before {
              content: "";
              border-top: 1px solid #d7dbec;
              position: absolute;
              top: 0;
              left: -10px;
              height: 1px;
              width: 100%;
            }
          }
        }

        &:first-child {
          .box-content {
            &::before {
              display: none;
            }
          }
        }
        &:last-child {
          &::after {
            display: none !important;
          }
        }

        &:hover {
          box-shadow: var(--bs-box-shadow);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .box-content-mobile {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      background: none;
      border: 0;

      .row-funds {
        .col-fund {
          background-color: #fff;
          border: 1px solid #eaedf1;
          border-radius: 12px;
          box-shadow: var(--bs-box-shadow);
          margin-bottom: 30px;
          padding: 25px 15px;

          &::after {
            display: none;
          }

          .col-right,
          .sub-col-right,
          .sub-col-left {
            padding: 0;
          }
          
          .box-content {
            padding: 0;

            &::before {
              display: none;
            }
            
            .box-content-row {
              &.box-content-desktop {
                display: none;
              }

              .title {
                margin-bottom: 7px;
              }
              .desc {
                margin-bottom: 0;
              }
              .number {
                margin-bottom: 4px;
              }
            }
          }
        }
      } 
    }

    @include media-breakpoint-down(sm) {
      .col-fund {
        margin-top: 40px !important;
        margin-bottom: 30px !important;
        
        .box-content-mobile {
          padding-top: 10px !important;
          text-align: center;
  
          .col-right {
            margin-top: 20px;
          }
  
          .image-wrapper {
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}