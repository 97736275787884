.dashboard-graph {
  #graphContainer {
    display:relative;
  }
  #chartOverlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: #fff;
    opacity: 0.95;
    display:flex;
    justify-content: center;
    align-items: center;
    span {
      background: #fff;
      width:80%;
    }
  }
}