.twofactormethod {
    .title {
        font-size: 22px;
        font-weight:bold;
        margin-bottom:15px;
    }
    .helptext {
        display:flex;
        flex-direction: row;
        font-size: 13px;
        margin-top: 15px;
        i {
            color:#f7ae49;
            margin-left: 15px;
            margin-right: 8px;
        }
    }
}
#pincode-input-auth2fa {
    font-size: 22px;    
    -moz-appearance: textfield;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}