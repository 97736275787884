table.event-list-table {
  a {
    text-decoration: none;
  }
}
table.event-choice-table {
    thead {
      th {
        vertical-align: top;
        border-bottom: 0;
      }
    }
    tbody {
      tr {
        td {
          font-size: 16px;
          padding: 40px 0;
          border: 1px solid #e5e5e5;
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
          .fundname {
            margin-bottom: 10px;
            font-size: 18px;
          }
          .fundbutton {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .progress-wrapper {
              display: flex;
              flex-direction: column;
              width: 40px;
              margin-top: 25px;
              margin-left: 8px;
              text-align: center;
              .progress-label {
                font-size: 13px;
              }
              .progress {
                margin: 0;
                height: 5px;
                .progress-bar {
                  background-color: #e9ac56;
                }
              }
            }
          }
          .choice-wrapper {
            display: inline-flex;
            flex-direction: column;
            padding: 0 30px;
            .pretty {
              label {
                display: inline-flex;
                align-items: flex-start;
              }
              i {
                margin-top: 2px;
                margin-right: 10px;
              }
            }
          }
        }
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
  ul.event-rules-list {
    li {
      margin-bottom: 10px;
    }
  }
  
  .event-management {
    &.event {
      .panel-heading {
        display: flex;
        justify-content: space-between;
      }
      .type {
        display: inline-block;
        padding-left: 10px;
        padding-top: 6px;
        font-weight: normal;
        font-size: 12px;
        color: #4b4b4b;
        vertical-align: top;
      }
      .description {
        font-weight: normal;
        padding: 15px;
        color: #31708f;
        background-color: #d9edf7;
        margin-bottom: 40px;
        word-wrap: normal;
      }
      .dates {
        font-weight: normal;
        font-size: 13px;
        color: #4b4b4b;
        padding-top: 4px;
      }
      .status {
        font-weight: normal;
        font-size: 13px;
        text-align: right;
        &.status1 {
          color: green;
        }
        &.status0 {
          color: red;
        }
      }
      table {
        th {
          border: 1px solid #e5e5e5;
          border-top: none;
          padding-bottom: 15px;
          .th-soft {
            font-weight: 300;
          }
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
        tr {
          td {
            vertical-align: middle;
              border: 1px solid #e5e5e5;
              &:first-child {
                border-left: none;
              }
              &:last-child {
                border-right: none;
              }
          }
        }
        tfoot tr td {
          padding-top: 15px;
          border: none;
        }
      }
      a {
        text-decoration: none;
      }
      .event-information {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 50px;
      }
    }
  }
  
  @media (max-width: 767px) { 
  
      table.event-choice-table {
          td {
              padding: 0 !important;
              display:block !important;
              border:none !important;
              text-align: left !important;
              div {
                  text-align: left !important;
              }
              .fundname {
                  font-size: 22px;
                  font-weight: bold;
              }
          }
          tr {
              td:last-child {
                  margin-bottom: 40px;
              }
          }
      }
      .eventheading {
        display:block;
          .btn {
              display: block;
              margin: auto;
              float: none;
          }
      }
      .event-dividend-submit {
          float:left !important;
      }
  }

  .event-dividend-block {

    .row {
      border-bottom: 1px solid #e5e5e5;
      padding-top: 7px;
      padding-bottom: 7px;
      margin: 0;

      .form-group {
        margin-bottom: 0;
      }
    }

    .btn-icon {
      padding: 2px 6px;
    }

    .btn.add-dividend {
      margin-top: 7px;
    }
  }

  .event-actions {
    a {
      text-decoration: none;
    }
  }