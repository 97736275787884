.page-funds {
  @include media-breakpoint-down(md) { 
    .col-column-container {
      order: 2;
    }
    .col-total-current-value-funds {
      order: 1;
    }
  }
}

.funds-column-container {
  .fund-column {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .fund-column-outer {
      .fund-column-top {
        position: relative;
  
        .heading {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 20px;
        }
  
        .image-wrapper {
          position: absolute;
          top: 8px;
          right: 0;
          z-index: 2;
  
          img {
            height: auto;
            max-width: 150px;
          }
        }
  
        .heading-line {
          opacity: .5;
          margin-bottom: 25px;
          position: relative;
          height: 1px;
          width: 95%;
        }

        @include media-breakpoint-down(md) { 
          .heading {
            max-width: 70%;
          }

          .image-wrapper {
            top: -12px;
            right: -20px;

            img {
              max-width: 100px;
            }
          }
        }
      }

      .fund-column-left {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        
        .box-green {
          margin: 0;
          
          &:last-of-type {
            margin-right: 0;
          }
          
          .box-green-inner {
            background-color: #eaf6f6;
            border-radius: 4px;
            padding: 10px 15px;
          }
  
          .heading {
            color: #495070;
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
          }
          
          .value {
            color: #131523;
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
          }
  
          .date {
            color: #7c8fac;
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
          }
  
          &.box-green-lg {
            width: 100%;

            @include media-breakpoint-down(lg) { 
              width: 70%; 
            }

            @include media-breakpoint-down(md) { 
              margin-top: 0;
              width: 100%; 
            }

            .box-green-inner {
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              padding: 20px;

              .heading, 
              .value {
                font-size: 18px;
              }
            }
          }

          &:not(.box-green-lg) {
            width: calc(50% - 10px); 
            
            @include media-breakpoint-down(md) { 
              width: 100%; 
            }
          }
        }

        @include media-breakpoint-down(lg) { 
          .box-green {
            margin: 0;
            
            .box-green-inner {
              .heading {
                font-size: 16px;
              }
            }

            &.box-green-lg {
              .box-green-inner {
                .heading, 
                .value {
                  font-size: 18px;
                }
              }
            }
          }
        }

        @include media-breakpoint-down(md) { 
          flex-direction: column;
        }
      }

      .fund-column-right {
        align-self: flex-end;
        margin-top: 30px;

        .button {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          &.button-bijstorten {
            padding-left: 12px;

            img {
              margin-top: -3px;
              height: auto;
              max-width: 20px;
            }
          }
        }
      }

      .fund-column-middle {
        display: flex;
        flex-direction: row;
        
        .box-green {
          margin-right: 25px;
          
          &:last-of-type {
            margin-right: 0;
          }
          
          .box-green-inner {
            background-color: #eaf6f6;
            border-radius: 4px;
            padding: 20px 25px;
          }
  
          .heading {
            color: #495070;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
          }
          
          .value {
            color: #131523;
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            margin-left: 10px;
          }
  
          .date {
            color: #7c8fac;
            font-size: 12px;
            font-weight: 300;
            margin-top: 7px;
          }
  
          &.box-green-lg {
            .box-green-inner {
              .heading, 
              .value {
                font-size: 18px;
              }
            }
          }
        }

        @include media-breakpoint-down(lg) { 
          flex-direction: column;

          .box-green {
            margin: 0 0 20px 0;
            
            .box-green-inner {
              .heading {
                font-size: 16px;
              }
            }

            &.box-green-lg {
              .box-green-inner {
                .heading, 
                .value {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .fund-column-bottom {
        margin-top: 30px;

        .button {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .fund-column-alt {
    margin-bottom: 20px;        
    
    &.fund-orange {
      .fund-column-top {
        p {
          color: $color__orange;
        }
      }
    }
    &.fund-teal {
      .fund-column-top {
        p {
          color: $color__teal;
        }
      }
    }
    &.fund-dark_blue {
      .fund-column-top {
        p {
          color: $color__dark_blue;
        }
      }
    }

    .fund-column-outer {
      box-shadow: none;
      padding: 30px 40px;
      position: relative;
      transition: box-shadow 0.3s ease;
      
      // @include media-breakpoint-up(lg) { 
      //   min-height: 628px;

      //   .fund-column-bottom {
      //     position: absolute;
      //     bottom: 30px;
      //     left: 50%;
      //     transform: translate(-50%);
      //   }
      // }

      &:hover { 
        box-shadow: var(--bs-box-shadow);
      }

      .fund-column-top {
        text-align: center;
        
        p {
          font-size: 18px;
          font-weight: 600;
          margin: 35px 0 0 0;
          padding: 0;
          min-height: 54px;
        }
      }
      
      .fund-column-middle {
        font-size: 14px;
        line-height: 1.75;
        margin: 35px 0 0 0;
        
        .numbers-table-row {
          display: flex;
          justify-content: space-between;
          
          .left {
            font-weight: 700;
          }
          
          .right {
            text-align: right;
          }
        }

        .actual-value {
          font-size: 18px;
          text-align: center;
          margin-top: 35px;

          .heading {
            color: #495070;
            font-weight: 700;
          }
        }
      }

      .fund-column-bottom {
        margin: 35px 0 0 0;
        text-align: center;
        
        .buttons-wrapper {
          .button {
            border-width: 1px;
            margin-bottom: 15px;
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.funddetail {
  .funds-column-container {
    .fund-column {
      margin-right: auto;
      margin-left: auto;
    }
  }
}


