@media print {
  .transactions {
    .do-not-print,
    .navbar-top .nav-right, 
    .navbar-top .nav-left, 
    .nav-getdone,
    .pagination, 
    .table-container-filters, 
    .table-container .filters-download {
      display: none !important;
    }

    .topbar {
      border: 0 !important;
      box-shadow: none !important;
    }

    .topbar-inner {
      display: block !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .navbar-top {
      padding: 0 !important;

      .nav-logo {
        margin-bottom: 20px !important;
        margin-left: -10px !important;
      }
    } 

    .footer {
      .container {
        display: block !important;
        padding: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        
        > {
          padding: 0 !important;
        }
      }
    }

    .doprint-table {
      display: table !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .doprint-block {
      display: block !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .table-container {
      border: 0 !important;
      padding: 0 !important;
    }
    
    .table-transactions tbody tr {
      display: table-row !important;
    }

    .table-transactions {
      width: 100%;
      border-collapse: collapse;
    }

    .table-transactions th, 
    .table-transactions td {
      padding: 8px;
      border: 1px solid #ddd;
    }
  }
  
  .do-not-print-always {
    display:none !important;
  }
}