.banner-outer {
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 25px !important;
  width: auto;

  img {
    height: auto;
    max-width: 100%;
  }

  .banner-row {
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    text-align: center;

    .col-img {
      margin-bottom: 30px;
    }
  }

  .row {
    align-items: center;
  }

  .content-wrapper {
    .heading-wrapper {
      .heading {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 30px;
      }
    }

    .content {
      p {
        color: #2a3547;
        font-size: 14px;
        padding: 0;
      }
      color: #2a3547;
      font-size: 14px;
      padding: 0;
    }
  }

  &.banner-lightteal {
    background-color: #f9ffff;
    border: 1px solid #aefbf7;
  }

  &.banner-green {
    background-color: $color__teal_light;
    border: 1px solid #aefbf7;
  }

  &.banner-orange {
    background-color: #fdf4de;
    border: 1px solid $color__orange;
  }
}

.jumbotron.banner-link {
  .bg {
    background: url("https://meewind.nl/wp-content/uploads/mijn.meewind.nl_ZBP_uitgifte_visual.png") no-repeat;
    background-position: right bottom;
    background-size: 47%;
    position: relative;
  }

  @media (max-width: 1199px) {
    padding: 40px !important;

    .bg {
      background: none !important;
    }
  }
}

.formwrap {
  #custom_area_form {
    .alert-info {
      flex-direction: column;
    }
    h3 {
      margin: 10px 0 25px;
    }
  }
}

.bannerform {
  .btn:not(.btn-arrow) {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 10px 15px !important;
  }
}