.threads-panel {
  .new-message-text {
    text-transform: capitalize;
  }
  .new-message-count {
    background-color: #09bdc6;
    border-radius: 50%;
    color: #fff;
    font-size: 0.7em;
    margin-left: 4px;
    padding: 2px 5px;
    position: relative;
    top: -1px;
    left: 0;
  }

  .message-item {
    border-bottom: 1px solid #edeeef;
    margin-bottom: 40px;
    padding: 0 0 40px;
    
    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 30px;
    }

    .message-content {
      p,
      span {
        padding-right: 0;
        padding-left: 0; 
      }

      small {
        display: block;
      }
    }
  }
}

.accordion-messages {
  .accordion-header {
    .accordion-button {
      &[aria-expanded="true"] {
        .date, 
        .status {
          display: none;
        }
      }
    }
  }
}