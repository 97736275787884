// Variables
@import "variables";
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import url("https://p.typekit.net/p.css?s=1&k=ckl7woj&ht=tk&f=28747.28754.28756.28758.28760.28762&a=1296137&app=typekit&e=css");

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/a?subset_id=2&fvd=n7&v=3") format("opentype");
    font-style:normal;font-weight:700;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/a?subset_id=2&fvd=n5&v=3") format("opentype");
    font-style:normal;font-weight:500;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/e3bd4a/00000000000000003b9ade5d/27/l?subset_id=2&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/e3bd4a/00000000000000003b9ade5d/27/d?subset_id=2&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/e3bd4a/00000000000000003b9ade5d/27/a?subset_id=2&fvd=n2&v=3") format("opentype");
    font-style:normal;font-weight:200;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/f90400/00000000000000003b9ade58/27/l?subset_id=2&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/f90400/00000000000000003b9ade58/27/d?subset_id=2&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/f90400/00000000000000003b9ade58/27/a?subset_id=2&fvd=n8&v=3") format("opentype");
    font-style:normal;font-weight:800;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/l?subset_id=2&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/d?subset_id=2&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
    font-style:normal;font-weight:300;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/277d7a/00000000000000003b9ade63/27/l?subset_id=2&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/277d7a/00000000000000003b9ade63/27/d?subset_id=2&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/277d7a/00000000000000003b9ade63/27/a?subset_id=2&fvd=n4&v=3") format("opentype");
    font-style:normal;font-weight:400;
}
.tk-filson-soft { font-family: "filson-soft",sans-serif; }

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

// Bootstrap
// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons";

@import '~pretty-checkbox/src/pretty.scss';
@import '~mdi/css/materialdesignicons.css';
@import '~pace-js/themes/orange/pace-theme-minimal.css';
@import "~bootstrap-pincode-input/css/bootstrap-pincode-input.css";
// @import "~select2/dist/css/select2.min.css";

@import "glyphicons"; 
@import "colors"; 
@import "style";
@import "nav-old";
@import "buttons";
@import "banners";
@import "breadcrumbs"; 
@import "accordions"; 
@import "tables"; 
@import "pagination"; 
@import "misc"; 
@import "nav";
@import "funds";
@import "database_documents";
@import "events";
@import "onboarding";
@import "progress_tracker";
@import "checkbox";
@import "radiobutton";
@import "loadingicon";
@import "bootstrap_hidden";
@import "applinks";
@import "password-toggle";
@import "highcharts";
@import "modals";
@import "widgets";
@import "2fa"; 
@import "overrides"; 
@import '~intl-tel-input/build/css/intlTelInput.css';
@import "messages_panel"; 
@import "cookie_notice"; 
// @import "select2selectbox";
@import "form_file_upload"; 
@import "box"; 
@import "box_funds_inline"; 
@import "page_login";
@import "page_funds"; 
@import "page_dashboard"; 
@import "page_documents"; 
@import "page_account"; 
@import "page_dashoverzicht";
@import "page_periodics";
@import "print_rules";
