.page-periodics-verify {
  form {
    .btn.pull-left, 
    .submitform {
      width: auto;
      
      @include media-breakpoint-down(lg) { 
        width: 100%;
      }
    }
  }
}