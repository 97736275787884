button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  box-shadow: none;
  float: none;
  margin: 10px 0 10px auto;
  width: auto;

  @include media-breakpoint-down(md) {
    margin: 10px auto;
  }
}

input[type="submit"] {
  display: block;
  padding: 10px 25px;
}

body {  
  .button,
  .modal-dialog .button {
    background-color: $color__orange;
    border: 2px solid $color__orange;
    border-radius: 4px;
    box-shadow: none;
    color: $color__white;
    cursor: pointer;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 700;
    padding: 7px 20px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
  
    &:visited {
      color: $color__white;
    }
  
    &:hover,
    &:active,
    &:focus {
      background-color: $color__white;
      border-color: $color__orange;
      color: $color__orange;
  
      &::after {
        color: $color__orange;
      }
    }
  
    &.button-narrow {
      font-weight: 500;
      padding: 1px 6px;
      pointer-events: none;
      min-height: unset;
      min-width: unset;
      width: auto;
  
      &::after {
        @include media-breakpoint-up(md) {
          font-size: 1.65rem;
          line-height: 2.3rem;
          top: -2px;
        }
      }
    }
  
    &.button-green {
      background-color: $color__green;
      border-color: $color__green;
  
      &::after {
        color: $color__white;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: $color__white;
        border-color: $color__green;
        color: $color__green;
  
        &::after {
          color: $color__green;
        }
      }
    }
  
    &.button-teal {
      background-color: $color__teal;
      border-color: $color__teal;
  
      &::after {
        color: $color__white;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: $color__white;
        border-color: $color__teal;
        color: $color__teal;
  
        &::after {
          color: $color__teal;
        }
      }
    }
  
    &.button-blue {
      background-color: $color__button-blue;
      border-color: $color__button-blue;
  
      &::after {
        color: $color__white;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: $color__button-blue;
        border-color: $color__button-blue;
        color: $color__white;
  
        &::after {
          color: $color__button-blue;
        }
      }
    }
  
    &.button-outline {
      background: $color__white;
      border: 2px solid $color__orange;
      color: $color__orange;
  
      &::after {
        color: $color__orange;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: $color__orange;
        border-color: $color__orange;
        color: $color__white;
  
        &::after {
          color: $color__white;
        }
      }
    }
  
    &.button-outline-green {
      background: $color__white;
      border: 2px solid $color__green;
      color: $color__green;
  
      &::after {
        color: $color__green;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: $color__green;
        border-color: $color__green;
        color: $color__white;
  
        &::after {
          color: $color__white;
        }
      }
    }
  
    &.button-borderless {
      background: none;
      border: 0;
      color: $color__teal;
      padding-left: 0;
  
      &::after {
        color: $color__teal;
      }
  
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: $color__orange;
  
        &::after {
          color: $color__orange;
        }
      }
    }
  
    &.button-back {
      padding: 10px 30px 10px 50px;
  
      @include media-breakpoint-down(md) {
        padding: 5px 20px 5px 35px;
      }
  
      &::after {
        content: "\F12C";
        left: 15px;
        right: unset;
  
        @include media-breakpoint-down(md) {
          font-size: 1.65rem;
          line-height: 2.6rem;
          top: -2px;
          left: 5px;
        }
      }
    }
  
    &.button-grey {
      background-color: #e5e9f4;
      border-color: #e5e9f4;
      color: #5a6a85;
      font-weight: 500;
    }
  
    &.button-disabled {
      background-color: #e5e9f4;
      border-color: #e5e9f4;
      color: #adadbf;
      pointer-events: none;
    }
  
    &.button-submit {
      appearance: none;
      -webkit-appearance: none;
      padding: 7px 30px;
  
      input {
        appearance: none;
        -webkit-appearance: none;
        background: none;
        border: 0;
        box-shadow: none;
      }
  
      &::after {
        content: "\F138";
        line-height: 3.2rem;
        left: 20px;
        right: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  
    @include media-breakpoint-down(md) {
      font-size: 0.9rem;
      line-height: 1.8rem;
  
      &::after {
        font-size: 1.6rem;
        line-height: 2.9rem;
        top: -5px;
        right: 5px;
      }
    }
  }
  
  .buttons-wrapper {
    a,
    .button {
      @include media-breakpoint-down(sm) {
        margin-right: 0.3rem;
      }
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .btn {
    border: 0 !important;
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
    padding: 10px 25px;
    text-decoration: none !important;
    
    &.btn-primary, 
    &.btn-warning {
      background-color: $color__orange;
      color: $color__white;
      font-size: 14px;
      font-weight: 500;
      
      &:hover {
        background-color: $color__orange;
        color: $color__white;
        opacity: .8;
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    .buttons-wrapper {
      .button {
        margin-bottom: 1rem;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  table {
    .btn,
    .button {
      padding: 2px 10px;
    }
  }
}  
