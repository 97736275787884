.section-heading {
  .heading {
    color: #2a3547;
    font-size: 22px;
    font-weight: 700;
  }
}

.image-wrapper {
  img {
    height: auto;
    max-width: 100%;
  }
}

.mdi-information {
  color: $color__orange;
}

.badge.badge-pill {
  background-color: #e6e9f4;
  color: #5a6a85;
  font-size: 11px;
  font-weight: 400;
  margin-left: 10px;
  
  @include media-breakpoint-down(lg) { 
    margin: 5px 0 0 0;
  }
}

.input-group {
  .input-group-addon {
    background-color: transparent !important;
    border: 0;
    line-height: 28px;
    padding: 4px 15px;
    position: absolute;
    top: 2px;
    left: 1px;
    z-index: 6;
  }

  &.input-currency {
    input {
      padding-left: 40px;
    }
  }

  &:not(.input-currency) {
    .input-group-addon {
      display: none;
    }
    input {
      border-radius: 4px;
    }
  }
}

.specialRadio,
.form-control {
  background: #fff;
  border: 1px solid #d9e1ec;
  border-radius: 4px !important;
  box-shadow: none;
  color: #5a607f;
  font-size: 12px;
  margin-bottom: 20px;
  min-height: 40px;

  &#amount {
    border-radius: 4px;
    padding-left: 45px;
    width: 100%;
  }
}

.panel {
  .panel-heading {
    border-bottom: 1px solid #d9d9d9;
    color: #535353;
    font-size: 20px;
    padding-bottom: 30px;
  }

  .panel-body {
    .form-group:first-of-type {
      .control-label {
        margin-top: 0;
      }
    }
    
    .form-vertical .form-group {
      margin-bottom: 0;
    }
  }

  p,
  .help-block {
    display: block;
    font-size: 14px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(sm) { 
  p,
  .help-block {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.pretty label span {
  margin-left: 5px;
}

select {
  cursor: pointer;
}

.form-group { 
  margin-bottom: 20px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  .img-responsive {
    height: auto;
    max-width: 100%;
  }

  .has-error {
    .input-group {
      display: block;
    }
  }

  &.radioWrapper {
    .wrapper {
      .radio {
        margin-left: 0;
        
        .radiocontainer {
          color: #2a3547;
          font-size: 14px;
          padding: 0 20px;

          .radiobutton {
            background-color: #fff;
            border: 1px solid #5d87ff;
            border-radius: 25px;
            position: absolute;
            top: 3px;
            left: 0;
            height: 15px;
            width: 15px;
          }

          input:checked ~ .radiobutton {
            background-color: #5d87ff;
            box-shadow: inset 0px 0px 0px 2px #fff;
          }
        }
      } 
    }
    }
  }

.modal, 
.filters-date {
  .form-group {
    input {
      max-width: 100%;
    }
  }
}
.filters-date {
  .form-group {
    margin-bottom: 0;

    input {
      margin-bottom: 0;
    }
  }
}

.specialRadio {
  border: 0;
  font-weight: 500;
  margin-right: 30px;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.form-bijstorten {
  .form-group {
    .specialRadio {
      display: block;
      width: auto;
  
      &:last-of-type {
        margin-right: 0;
      }
  
      input,
      span {
        color: #2a3547;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .buttons-wrapper {
    display: flex;

    .btn.pull-left {
      float: none;
      margin: 10px 0 10px 0;
    }

    @include media-breakpoint-down(md) { 
      flex-direction: column;

      .btn {
        width: auto;
      }
    }
  }
}

p,
.help-block {
  display: block;

  img {
    margin-top: -3px;
    margin-right: 4px;
  }

  .incasso, 
  .emissiekosten {
    color: #57bcb7;
    font-weight: 700;
  }

  strong {
    font-weight: 600;
  }
}

.error-help-block {
  color: #d22626 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
  // padding: 4px; this makes the forms jump on /incasso
  
  &#ok-error {
    // border-top: 1px solid #d32626;
    margin-top: 10px !important;
    padding: 10px 0 0 0 !important;
  }

  &#amount-error {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    padding-left: 0 !important;
  }
}

.formwrap {
  margin-bottom: 20px;

  .divider {
    border-bottom: 1px solid #d9d9d9;
  }

  .form-group {
    &.contains-checkbox {
      margin-bottom: 20px;
      padding: 0 15px;

      .checkbox {
        font-size: 12px;
      }
    }
  }
}

.formBackdrop {
  display: none !important;
}

select:not(.form-select) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
  background-position: calc(100% - 12px) 1.2em, calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em !important;
  background-size: 10px 10px, 10px 10px, 1px 1.5em !important;
  cursor: pointer;
}

.transaction-overview {
  .date {
    width: 100px;
  }
  .value {
    width: 150px;
  }

  .brutto-price,
  .netto-price {
    width: 120px;
  }
  .costs {
    width: 100px;
  }

}

.alert {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;

  &.alert-withimage {
    display: block;
  }

  &:not(.alert-info) {
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    padding: 16px !important;
  }

  &.alert-success {
    background-color: #ebf9f9;
    border-color: #aefbf7;
    color: #57bcb7;

    button {
      &.close {
        color: #57bcb7;
      }
    }
  }

  &.alert-warning {
    background-color: #faebcc;
    border-color: $color__orange;
    color: $color__orange;
    
    p {
      color: $color__orange !important;
    }

    button {
      &.close {
        color: $color__orange;
      }
    }
  }

  &.alert-danger {
    background-color: #f8d7da;
    border-color: #d7727b;
    color: #d12b3a;

    button {
      &.close {
        color: #d12b3a;
      }
    }

    span {
      &::before {
        display: none;
      }
    }

    ul {
      margin-bottom: 0;
    }
  }

  &.alert-success.alert-important {
    .close {
      display: none;
    }
  }

  .alert-logout {
    font-weight: 400;
    padding-right: 100px;
    padding-left: 21%;
    position: relative;

    &::before {
      content: "";
      background: url("/images/welkom_banner_image_large.png");
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      height: 180px;
      width: 180px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .desc {
      font-size: 14px;
      margin-bottom: 30px;
      padding: 0;
    }

    @include media-breakpoint-down(xl) { 
      padding-right: 80px;
      padding-left: 26%;
    }

    @include media-breakpoint-down(lg) { 
      padding-right: 10px;
      padding-left: 30%;
      
      &::before {
        width: 150px;
        height: 150px;
      }
    }

    @include media-breakpoint-down(md) { 
      padding-right: 0;
      padding-left: 0;
      
      &::before {
        display: none;
      }

      .buttons {
        text-align: center;
      }
    }
  }

  button {
    &.close {
      background: none;
      margin: 0;
      padding: 0 10px;
      position: relative;
      top: -1px;
      left: -7px;
    }
  }

  .iconbg {
    display: none !important;
  }

  span {
    padding: 0 !important;
  }

  @include media-breakpoint-down(md) { 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

input[type=file] {
  min-height: 32px;
  height: auto !important;
}

.form-columns {
  input,
  select {
    max-width: 100%;
  }
}

.form-flex-columns {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .form-group {
      padding-right: 20px;
    }
    
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      
      .form-group {
        padding-right: 0;
        width: 100% !important;
      }
    }
  }
}

form {
  &:not(.onboarding-form) {
    .control-label {
      color: #5a607f;
      font-size: 16px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 10px;
    }
  
    // @include media-breakpoint-up(xl) { 
    //   input:not([name*="password"]),
    //   select {
    //     max-width: 82%;
    //   }
    // }
  
    .radio-inline {
      margin-right: 10px;
  
      input {
        margin-right: 5px;
      }
  
      &:last-of-type {
        margin-right: 0;
      }
    }
  
    .form-group {
      &.radioWrapper {
        display: block;
        width: 100%;
      }
      
      input, 
      select, 
      textarea {
        &:not([type="submit"]) {
          &:not([type="checkbox"]) {
            &:not([type="radio"]) {
              &:not(.w-auto) {
                width: 100%;
              }
            }
          }
        }
  
        &[disabled] {
          cursor: default !important;
  
          &.btn {
            filter: grayscale(100%);
          }
        }
  
        &.short {
          width: 50%;
        }
      }
  
      &.form-submit {
        padding: 0;
      }
  
      &.disabled {
        cursor: default !important;
  
        * {
          cursor: default !important;
        }
  
        .radiobutton {
          background-color: #fff !important;
          filter: grayscale(100%);
        }
        
        input:checked ~ .radiobutton {
          background-color: #5d87ff !important;
        }
      }
  
      .radio,
      .checkbox {
        display: inline-block;
        margin-right: 15px;
        margin-left: 0;
         
        input {
          margin-right: 10px;
          width: auto;
        }
      }
    }
  
    .featured-label {
      margin-bottom: 25px;
  
      .control-label {
        color: #131523;
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  
    .form-control::file-selector-button {
      padding: 11px;
    }
  
  }

  &.login-form {
    input,
    select {
      max-width: 100% !important;
    }
    
  }
}

.panel {
  background-color: #fff;
  border: 1px solid #eeeff4;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px;
  
  @include media-breakpoint-down(sm) { 
    margin-bottom: 0;
  }

  .panel-heading {
    border-bottom: 1px solid #d9d9d9;
    color: #5a607f;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px 0;
    padding: 0 0 25px 0;
  }

  .panel-body {
    padding: 0;
  }

  p {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Temporary solutions - to delete after refactoring BootForm */
.form-group {
  a {
    font-size: 12px;
    text-decoration: none;
  }
}
.phone_secondary {
  .form-group  {
    .help-block {
      position: absolute;
      top: 1px;
      left: 175px;
    }
  }
}
.iban {
  .form-group  {
    .help-block {
      position: absolute;
      top: 1px;
      left: 185px;
    }
  }
}
/* Temporary solutions - to delete after refactoring BootForm */

.dropdown-item {
  &:active {
    background-color: #f8f9fa;
    color: #212529;
  }
}

.hidden {
  display: none !important;
}

.btn.pull-left {
  background: #09bdc6;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0 10px auto;

  @include media-breakpoint-down(md) { 
    display: block;
    float: none;
  }
}

.loadingIcon {
  margin-left: 10px;
  position: relative;
  top: 45%;
  transform: translateY(-50%);
}

.fundsDetails {
  label {
    color: #5a607f;
  }
}

.form-group {
  .collapse.in {
    display: block;
  }
}

.switchRadios {
  .radio {
    .radioWrap {
      input[type=text] {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 0 10px !important;
      }
    }

    .radioWrap-borderless {
      background: none !important;
      border: 0 !important;
      padding: 0 !important;

      label {
        padding-top: 4px !important;
      }

      input[type=text] {
        padding: 5px 10px !important;
      }

      .error-help-block {
        margin-top: 10px !important;
        margin-bottom: 5px !important;
        padding-left: 0 !important;
      }
    }
  }
} 

@include media-breakpoint-down(lg) {
  .logout-top {
    display: none;
  }
}

@include media-breakpoint-down(md) { 
  .modal-backdrop {
    display: none !important;
  }

  .submitform {
    margin-top: 15px !important;
    width: 100% !important;
  }

  .alert.alert-withimage {
    position: relative;

    &::before {
      content: "";
      background-color: rgba(255, 255, 255, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  .switchRadios {
    .container-fluid.radioWrapper {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  th, tr {
    min-width: 130px;
  }
}

/* Fix to avoid zoom on inputs on mobile */
@include media-breakpoint-down(sm) { 
  input[type="text"], input[type="password"], input[type="email"] {
    font-size: 16px;
  }
}

.bank-transfer-instructions {
  .col-left {
    letter-spacing: -0.5px;
  }
}

.placeholder-orange {
  &::placeholder {
    color: $color__orange;
    opacity: 1;
  }
}

input[type='radio'], 
input[type='checkbox'] {
  accent-color: rgb(216, 126, 39);
}

.font-small {
  color: #5a607f !important;
  font-size: 12px !important;
  
  label {
    span {
      color: #5a607f !important;
      font-size: 12px !important;
      line-height: 22px !important;
    }
  }
}

.help-block {
  .help-block-heading {
    margin-bottom: 10px;
  }
}

.jsPhone {
  min-width: 220px;
}
