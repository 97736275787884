.database-table-all-docs .doc-row, .database-table-all-docs input[type=checkbox]{
    cursor: pointer;
  }
  
  .database-table-all-docs .doc-row.checked{
    background-color: #ebebeb;
  }
  
  .database-table-all-docs .checkbox {
    margin-bottom: 0;
  }
  
  .database-table-all-docs .checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
  }
  
  .database-table-all-docs  .checkbox .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 15%;
  }
  
  .database-table-all-docs .checkbox input[type="checkbox"] {
    display: none;
  }
  
  .database-table-all-docs .checkbox input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
  }
  
  .database-table-all-docs .checkbox input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
  }
  
  .database-table-all-docs .checkbox input[type="checkbox"]:disabled+.cr {
    opacity: .5;
  }
  
  .btn-doc-select-all{
    margin-right: 5px;
  }
  
  .btn-doc-upload{
    margin-right: 5px;
  }
  
  #modal-doc-upload .modal-dialog{
    overflow-y: initial
  }
  
  #modal-doc-upload .modal-confirmation-body{
    max-height: 250px;
    overflow-y: auto;
  }
  .progress-line, .progress-line:before {
    height: 10px;
    width: 100%;
    margin: 0;
  }
  .progress-line {
    background-color: #cdf1f3;
    display: -webkit-flex;
    display: flex;
  }
  .progress-line:before {
    background-color: #09bdc6;
    content: '';
    -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  
  @-webkit-keyframes running-progress {
    0% { margin-left: 0px; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
  }
  @keyframes running-progress {
    0% { margin-left: 0px; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
  }
  
  .modal-confirmation-body{
    padding: 5px;
  }
  
  #modal-doc-upload .progress-line{
    margin-bottom: 10px;
  }
  
  .database-table-all-docs {
    .doc-row {
      .title{
        width: 10%;
      }
      .description{
        width: 20%;
      }
      .trans-icon{
        text-align: center;
      }
    }
  }
  
  .user-detail-document-table{
  
    .document-badge {
      width: 10%;
    }
  }
  