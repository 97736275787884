$darkgreen: #15A087;
html.wait, html.wait * { cursor: wait !important; }
body {
    font-weight:300;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  font-family: "Inter", sans-serif;
  margin-bottom: 204px;
  min-height:100%;
  background: #fafafa;
  background-image: url('/images/footer-bg.webp');
  background-position: bottom 38px center;
  background-repeat: no-repeat;
  background-size: 2000px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 204px;
  background-color: #f5f5f5;
  margin-top: 200px;
}
.mb-1 {
    margin-bottom: 0.5rem;
}
.mr-1 {
    margin-right: 1rem;
}
.mr-2 {
    margin-right: 2rem;
}
.mt-2 {
    margin-top: 2rem;
}
.font-16 {
    font-size:16px;
}
.onboarding.font-16 {
    font-size:16px;
    .control-label,
    p,
    .form-control,
    .radioWrapper .wrapper .radio .radiocontainer,
    .btn {
        font-size:16px;
    }
    .form-group {
        margin-bottom: 15px;
    }
}
.regular {
    font-family: "museo-sans", sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: #4b4b4b;

}

@media (min-width: 1400px) {
    .container {
        width: 1240px;
    }
}

input[type="file"] {
    height:auto !important;
}

select:not(.form-select) {
    appearance: none;
    -webkit-appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%) !important;
    background-position:
        calc(100% - 20px) calc(1em + 4px),
        calc(100% - 15px) calc(1em + 4px),
        calc(100% - 2.5em) 0.5em !important;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em !important;
    background-repeat: no-repeat !important;
}

.successIcon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAYAAADZsVyDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNDhCMTY1N0Q2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNDhCMTY1OEQ2NDMxMUVBOTdFN0VFNTI4OENCQUM2MCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjI0OEIxNjU1RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjI0OEIxNjU2RDY0MzExRUE5N0U3RUU1Mjg4Q0JBQzYwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+C7PaoQAAASRJREFUeNpi/P//PwM1gNrCjnYgFQvEB4A4jYVKhk4GUjlQbjQQszJS6mKgoVOAVDaa8B8mKrg0G4vURiYKDJ2I5H1ksBOIE5goMDQPi9RuIA6+FV/xhQWqkAtIaQDxVaDgTwoMDQTq/wriMAEVykKTyFmQJJAvSiBM83B4PwhmKNhgIF4CxKZQvi0QbwEaIEYgSSGDHUAcCvI+siDIYAM0hWZAvAlokDgRhu6EGvoZXYJRdUF7JZBuw6LpBBBHAnEuEBcRClMMg0EZBOiiOiC7EYv8WyAWxuHSYFyGwg2GeheX4bgi6hs+RfB0DFTYBKTqCRi6HYhDCBmKYjARhoNiPxw99okyGMnwRizeD8MW+wTDGEu6rYdmhhNQQ7+Sku0BAgwAqNZ6u3oIY6kAAAAASUVORK5CYII=) !important;
    display: inline-block;
    width: 22px;    
    height: 17px;
}

.zoombox {
    text-decoration: none;

    // .hoverlarge {
    //     &:hover {
    //         position: absolute;
    //         width:400%;
    //         height:auto;
    //         -webkit-transform: translateX(-50%) translateY(-50%);
    //         -ms-transform: translateX(-50%) translateY(-50%);
    //         -o-transform: translateX(-50%) translateY(-50%);
    //         transform: translateX(-50%) translateY(-50%);
    //         left: 50%;
    //         top:50%;
    //         max-width: 100vh;
    //     }
    // }

}

.gly-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
}
.formBackdrop {
    position:fixed;
    display:none;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:1000;
    background:rgba(255,255,255,0.8);
}
@media (max-width: 992px) {
    .formBackdrop {
        z-index:auto;
    }
}
.forminfocus {
    position:relative;
    border:1px solid #efefef;
}
.formwrap .alert-danger,
.formwrap .alert-info {
    position:relative;
    z-index:1;
}

.formwrap {
    .panel-heading {
        position:relative;
        transition: all 1s ease-out;
        &:after {
            position:absolute;
            content: " ";
            bottom:-1px;
            left:0px;
            right:0px;
            display:block;
            height:2px;
            background-color:#ef9147;
            width:0%;
            transition: width 1s ease;
        }
    }
    &.loading .panel-heading:after {
        width: 100%;
    }
}

.emptyMessage {
    padding-left:0 !important;
}
.table.clickrows tbody tr:hover td.emptyMessage, 
.table.clickrows tbody tr.clicked td.emptyMessage {
    background:none !important;
}

.accountcolorForced {
    color:#eeb013 !important;
}

.enableform:hover {
    cursor: pointer;
}

.text-left-override {
    text-align: left !important;
}

a.glyphicon:hover {
    text-decoration: none;
}

.assign_holding:hover {
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}

.nowrap {
    white-space: nowrap;
}
.alert {
  border-radius: 4px;
  position: relative;
}
.alert.alert-success>ul,
.alert.alert-danger>ul {
    padding-left: 55px;
    display: block;
}
.alert.alert-danger {
    a {
        font-weight:bold;
        text-decoration:underline;
    }
}
.alert.alert-success {
    span {
        padding-left:58px;
        padding-right:58px;
        display: block;
    }
    .iconbg {
        position:absolute;
        display:block;
        top:0;
        left:0;
        bottom:0;
        width:55px;
        list-style: none;
        background:#d0edc6;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    i {
        position:absolute;
        left:50%;
        top:50%;
        margin-top:-6px;
        margin-left:-6px;
        color:#3c763d;
    }
}

.alert.alert-info {
  span {
    display: block;
    padding-left: 58px;
}
  .iconbg {
    position:absolute;
    display:block;
    top:0;
    left:0;
    bottom:0;
    width:55px;
    list-style: none;
    background:#bce8f1;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
  a {
    &:hover {
      color: $color__orange;
    }
  }
}
.alert.alert-disclaimer {
  background: none;
  border: none;
  color: #5a607f;
  display: block;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  position: relative;

  strong {
    font-weight: 700;
  }
}
.alert.alert-warning {
    span {
        display:block;
        padding-left:58px;
        display: block;
    }
    .iconbg {
        position:absolute;
        display:block;
        top:0;
        left:0;
        bottom:0;
        width:55px;
        list-style: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
}
.alert.alert-info i {
    position:absolute;
    left:50%;
    top:50%;
    margin-top:-6px;
    margin-left:-6px;
    // -webkit-transform: translateX(50%) translateY(50%);
    // -ms-transform: translateX(50%) translateY(50%);
    // -o-transform: translateX(50%) translateY(50%);
    // transform: translateX(50%) translateY(50%);
    color:#31708f;
}

.authBlock1 {
    background: #fff;
    padding-top:10px;
    padding-bottom:10px;
    img {
        display:inline-block;
    }
}
.main-content-wrapper .container.authBlock2 {
    padding-top: 20px;
    padding-bottom: 30px;
    .h1 {
        font-size: 34px;
        margin-top:0;
        color: #e89422;
        font-weight: 700;
    }
    p {
        font-size: 16px;
    }
}
@media (max-width:768px) {
    .main-content-wrapper .container.authBlock2 {
        padding-bottom: 10px;
        .h1 {
            font-size: 28px;
        }
    }
    .topheader {
        border-bottom:1px solid #eceae9;
    }   
}

.panel.exportpanel {
    .form-horizontal {
        padding:20px 30px;
    }
    .form-group {
        margin-bottom:0;
    }
}

.alert.alert-danger {
    padding-left:65px;
    span {
        display:block;
        padding-left:10px;
        &:before {
            content:"•  ";
        }
    }
    .error-help-block {
        display:inline-block !important;
        margin:0;
        color:#a94442;
    }
    .iconbg {
        position:absolute;
        display:block;
        top:0;
        left:0;
        bottom:0;
        width:55px;
        list-style: none;
        background:#edc8c5;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    i {
        position:absolute;
        left:50%;
        top:50%;
        margin-top:-6px;
        margin-left:-6px;
        color:#e33131;
    }
}

.alert.alert-warning {
    padding-left:65px;
    span {
        display:block;
        padding-left:10px;
    }
    .error-help-block {
        display:inline-block !important;
        margin:0;
        color:#8a6d3b;
    }
    .iconbg {
        position:absolute;
        display:block;
        top:0;
        left:0;
        bottom:0;
        width:55px;
        list-style: none;
        background:#faebcc;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    i {
        position:absolute;
        left:50%;
        top:50%;
        margin-top:-6px;
        margin-left:-6px;
        color:#8a6d3b;
    }
}

.iconwrap {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    .numberCircle {
        position: absolute;
        top: -4px;
        right: -12px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        padding: 3px;
        background: #d22626;
        border: 1px solid d22626;
        color: #fff;
        text-align: center;
        font: 8px Arial, sans-serif;
        display: inline-block;
    }
}

.versionWrap {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    .numberCircle {
        position: absolute;
        bottom: -7px;
        right: -203px;
        width: 200px;
        height: 22px;
        padding: 3px;
        color: #000;
        text-align: left;
        font: 12px Arial, sans-serif;
        display: inline-block;
    }
}

.has-error .form-control {
    border-width:2px;
    border-color:#d22626;
}
.has-error .checkbox label {
    color:#d22626;
}
// .error-help-block,
// .input-group span.error {
//     display:none !important;
// }
.error-help-block {
    color: red !important;
    font-weight: bold;
    display: inline-block;
}

.has-success .form-control,
.has-error .form-control:focus {
    border-color: #ececec;
    box-shadow: none;
}

// .has-success input[name="username"] {
//     background:url('/images/check.png') right 5px center no-repeat !important;
// }

.feedbackList {
    li {
        list-style:none;
    }
    li:before {
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        font-size:18px;
    }
    &.ok {
        li:before {
            color: green;
            content: "\f00c"; /* FontAwesome Unicode */
        }
    }
    &.nok {
        li:before {
            color: red;
            content: "\f00d"; /* FontAwesome Unicode */
        }
    }
}
.has-error .input-group-addon {
  border-color:#ececec;
}
.has-success .input-group-addon {
    border-color: #ececec;
    box-shadow: none;
    background-color: #f2f2f2;
}
.has-success .input-group-addon .glyphicon-calendar {
    color:#eeb013;
}
.calendar .input-group-addon:hover {
    cursor:pointer;
}

// @media(min-width:768px) {
//     .bsnMarginFix {
//         margin-top:-10px;
//     }
// }

/**
 * Generic styles
 */

.font12 {
    font-size: 12px;
}

.margin-left-15 {
    margin-left: 15px;
}
.margin-bottom-15 {
    margin-bottom: 15px;
}
.mb-30 {
    margin-bottom: 15px;
}

.font14 {
    font-size: 14px;
}
.font-light {
    font-weight:300;
}
.fontTotalvalue {
    font-size: 29px;
}

.tablebtn {
    display:inline-block;
    padding:5px;
    &.view {
        color:green;
    }
    &.edit {
        color:blue;
    }
    &.download {
        color:black;
    }
    &.delete {
        color:grey;
    }
}

a.btn-add,
.btn-add {
    position:relative;
    background: url('/images/arrow.png') no-repeat right 15px center #75bd6d;
    padding-right: 70px !important;
    border: 1px solid #75bd6d;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding:9px 12px;
    img {
        position:absolute;
        right: 48px;
        top:50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.btn-add:hover,
.btn-add:focus,
.btn-add.focus {
    color: #fff;
}

a.btn-green,
.btn-green {
  position:relative;
  background: #75bd6d;
  border: 1px solid #75bd6d;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding:6px 25px;

  &:hover {
    background: #75bd6d;
    color: #fff;
  }
}

a.btn-white,
.btn-white {
  position:relative;
  background: #fff;
  color: #ef9008;
  border-color: #ef9008;
  font-size: 15px;
  font-weight: 700;
  padding:6px 25px;

  &:hover {
    background: #fff;
    color: #ef9008;
  }
}

a.btn-darkgreen,
.btn-darkgreen {
    position:relative;
    background: #086375;
    border: 1px solid #086375;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding:6px 25px;

    &:hover {
      background: #086375;
      color: #fff;
    }
}

a.btn-grdgreen,
.btn-grdgreen {
  position:relative;
  background: #3e9f87;
  border: 1px solid #3e9f87;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding:6px 25px;

  &:hover {
    background: #3e9f87;
    color: #fff;
  }
}

a.btn-buy,
.btn-buy {
  background: url('/images/arrow.png') no-repeat right 15px center #eeb013;
  padding-right: 45px !important;
  border: 1px solid #eeb013;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.btn-buy:hover,
.btn-buy:focus,
.btn-buy.focus {
  background-color: #eeb013;
  color: #fff;
}

// .contain-add-btn {
//     border-top: 1px solid #75bd6d !important;
//     border-bottom: 1px solid #75bd6d !important;
// }

// .contain-buy-btn {
//     border-top: 1px solid #eeb013 !important;
//     border-bottom: 1px solid #eeb013 !important;
// }

.tempMsg {
    display: inline-block;
    margin-left: 20px;
}

.slash {
    color: #ccc;
}

.panel-default {
    // border:1px solid transparent;
    .panel-subheading {
        padding: 10px 0 15px 0;
        margin: 0 35px;
        font-size: 18px;
        font-weight: 700;
        color: #000;
    }
}

.panel-default>.panel-subheading {
    color: #333333;
    background-color: #fff;
    border-color: #eceae9;
}

.panel-subheading {
    padding: 10px 15px 10px 15px;
    border-bottom: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.adjust-huener {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.adjust-huener > div {
  background-color: #eeb013;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.adjust-huener .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.adjust-huener .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.adjust-huener .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.adjust-huener .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.profile_actions {
    display: inline-block;
}

.dataTables_wrapper .dataTables_processing {
    background:none;
    border:none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color:#fff;
    width:50px;
    height:40px;
    margin-left:-25px;
    margin-top:20px;
}
.adjust-huener {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.adjust-huener > div {
  background-color: #eeb013;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.adjust-huener .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.adjust-huener .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.adjust-huener .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.adjust-huener .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
.hidden {
    display:none;
}
.hidden-for-toggle {
    display:none;
}
.ldiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(255,255,255,0.90);
    padding-top: 8%;
    z-index:1090;
    cursor: default;
    
    .adjust-huener {
        margin:0 auto;
    }
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;
        .text {
            position: absolute;
            bottom: -60px;
            left: 50%;
            transform: translateX(-50%);
            font-weight: bold;
            font-size: 16px;
            width: 300px;
        }
    }
      
    .spinner .rect {
        z-index: 999;
        background-color: #f9a92b;
        height: 100%;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }
    
    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }
    
    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }
    
    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    
    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
    
    @-webkit-keyframes sk-stretchdelay {
        0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
        20% { -webkit-transform: scaleY(1.0) }
    }
    
    @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
    }
}
.status {
    .glyphicon-ok {
        color:$darkgreen !important;
    }
    .glyphicon-remove {
        color:#d9534f !important;
    }
}

.footer {
    background:#fff;
    margin-top:80px;
    .container {
        padding-right:40px;
        padding-left:40px;
        padding-bottom:80px;
        padding-top:80px;
    }
}

/**
 * Bootstrap overrides
 */
a {
    color:inherit;
    text-decoration:underline;
    &:hover {
        text-decoration:underline;
        color:inherit;
    }
}

.text-accent {
    &.green {
        color:#00a556;
    }
    &.darkgreen {
        color: $darkgreen !important;
    }
    &.bold, &.strong {
        font-weight:700;
    }
}
.btn {
    border-radius: 20px;
    padding:6px 25px;
    text-decoration:none;
    &.btn-primary {
        font-weight:700;
        font-size:15px;
        background:#f7ae49;
        border:none;
        border-bottom: 2px solid #f7ae49;
        &:focus,
        &:hover {
            background:lighten(#f7ae49, 15%);
            border-bottom: 2px solid #ef9147;
            box-shadow:none;
            outline:none;
        }
        &:active {
            outline:none;
            background:lighten(#f7ae49, 30%);
            border-bottom: 2px solid #f7ae49;
        }
    }
    &.btn-success {
        &:focus,
        &:hover {
            box-shadow:none;
            border-color: transparent;
            outline:none;
        }
        &:active {
            outline:none;
        }
    }
    &.btn-secondary {
        font-weight:700;
        font-size:15px;
        background:#09bdc6;
        border:none;
        color: #fff;
        border-bottom: 2px solid #09bdc6;
        &:focus,
        &:hover {
            background:lighten(#09bdc6, 15%);
            border-bottom: 2px solid #09bdc6;
            color: #fff;
            box-shadow:none;
            outline:none;
        }
        &:active {
            outline:none;
            background:lighten(#09bdc6, 30%);
            border-bottom: 2px solid #09bdc6;
        }
    }
    &.btn-sgg {
        font-weight:700;
        font-size:15px;
        background:#21aeec;
        color:#fff;
        border:none;
        border-bottom: 2px solid #248dbb;
        &:focus,
        &:hover {
            background:#2abeff;
            border-bottom: 2px solid #248dbb;
            box-shadow:none;
        }
        &:active {
            outline:none;
            background:#2abeff;
            border-bottom: 2px solid #248dbb;
        }
    }

    &.btn-danger {
        font-weight:700;
        font-size:15px;
        background:#e33131;
        border:none;
        border-bottom: 2px solid #ce2828;
        &:focus,
        &:hover {
            background:#ed5555;
            border-bottom: 2px solid #ce2828;
            box-shadow:none;
            outline:none;
        }
        &:active {
            outline:none;
            background:#ed5555;
            border-bottom: 2px solid #ed5555;
        }
    }

    &.btn-link {
        color:#4b4b4b;
        font-weight:300;
    }

    &.btn-stacked {
        margin-bottom: 5px;
    }

    &.btn-pulled-right {
        margin-left: 5px;
    }

    &.btn-green {
        color: #fff;
        background-color: $darkgreen;
        border-color: $darkgreen;
        &:hover,
        &:active {
            color: #fff;
            background-color: lighten($darkgreen, 30%);
            border-color: lighten($darkgreen, 30%);
        }
    }

    &.btn-red {
        color: #fff;
        background-color: #d9534f;
        border-color: #d9534f;
        &:hover,
        &:active {
            color: #fff;
            background-color: #c9302c;
            border-color: #c12e2a;
        }
    }

    &.btn-info {
        color: #fff;
        background-color: #086375;
        border-color: #fff;
        &:hover,
        &:active {
            color: #fff;
            background-color: #308292;
            border-color: #fff;
        }
    }
}

.pretty {
    label {
        font-weight:inherit;
        span {
            line-height: 24px;
        }
    }
    &.nopad {
        label {
          padding-left:0;
          .mdi {
            margin-right: 10px;
          }
        }
    }
}
.specialRadio {
    padding:10px;
    background:#fdfdfd;
    border:1px solid #ececec;
    margin-bottom:20px;
    width:100%;
    small {
        font-size:12px;
    }
    .mdi {
        margin-right: 10px;
    }
    &:last-child {
        margin-bottom:0;
    }
}
.has-error {
    .specialRadio.grid.pretty {
        border-width: 2px;
        border-color: #d22626;
    }
    input:invalid, [aria-invalid] {
        border-width: 2px;
        border-color: #d22626;
    }
}
.prettyRadioGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -10px;
    .specialRadio.grid.pretty {
        width: 46.5%;
        margin-bottom: 10px;
        &:last-child {
            width: 96.5%;
            background:none;
            border:none;
        }
    }
    .form-group {
        width: 96.5%;
    }
    .radio + .radio, .checkbox + .checkbox {
        margin-top:0;
    }
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #eeb013;
    text-align: center;
    background-color: #f2f2f2;
    border:1px solid #ececec;
    border-right:0;
    border-radius:0;
}
.table.align-middle > tbody > tr > td {
    vertical-align: middle;
}
.form-regular {
    .form-group {
        float: left;
    }
}
.form-vertical {
    .form-group {
        margin-bottom: 30px;
    }
}
.panel-heading a {
    text-decoration:none;
}

.panel-bordered {
    border-radius: 0;
    border: 1px solid #e2e2e2;
    .panel-heading {
        padding: 20px 0 15px 0;
        margin: 0 30px;
        font-size: 22px;
        font-weight: 700;
        color: #f7ae49;
        border-color: #eceae9;
        background: none;
    }
    .panel-body {
        padding: 30px 30px 15px 30px;
    }
}
.panel-default {
    border-radius: 0;
    // border: none;

    .panel-heading {
        padding: 20px 0 15px 0;
        margin: 0 30px;
        font-size: 22px;
        font-weight: 700;
        color: #f7ae49;
        background: none;
    }
    .panel-body {
        padding: 25px 30px 15px 30px;
    }
}

.panel-danger {
    border: 2px solid #d62b2b;
    .panel-heading {
        background: #fff;
    }
}

.panel-success>.panel-heading {
    background: none;
}

.alert-info.clean {
    background: none;
    border: none;
    display: block;
    position: relative;
    padding-left: 35px;
    color: #5a607f;
    font-size: 12px;
    line-height: 18px;
    &:before {
      background-image: url('/images/info-icon.png');
        content: "";
        position: absolute;
        left: 0;
        top: 17px;
        display: inline-block;
        color: #f9a92b;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
        height: 14px;
        width: 14px;
    }
}


.form-control {
    border-radius: 0;
    border: 1px solid #ececec;
    background: #fdfdfd;
    box-shadow:none;
    &.initials {
      text-transform: uppercase;
      // width:70px !important;
    }
    // &.sex {
    //   width:70px !important;
    // }
    // &.dob {
    //     width:130px !important;
    // }
    // &.phone {
    //     // width:130px !important;
    // }
    // &.add {
    //     width:70px !important;
    // }
    // &.postcode {
    //     width:130px !important;
    // }
    // &.rek {
    //     width:200px !important;
    // }
    &#startdate {
      width:200px !important;
    }
    &.bsn {
      // width:100px !important;
      display:inline-block;
      margin-right:20px;
      &~.help-block {
        display:inline-block;
      }
    }
}

.control-label {
    font-size:16px;
    font-weight:700;
}

.switchRadios.has-error {
    .radio {
        .radioWrap {
            input[aria-describedby="amount-error"],
            input[aria-describedby="value-error"] {
                border:2px solid red;
            }
        }
    }
}
.has-error {
    .checkbox.pretty {
        border: 1px solid red;
        padding: 10px;
    }
}
.switchRadios {
    .radio {
        margin-bottom:20px;
        .radioWrap {
            background:#fdfdfd;
            border:1px solid #ececec;
            border-radius: 4px;
            padding:10px;
            input[type="text"] {
                background:#fdfdfd;
                border:none;
                padding:0;
                margin:0;
            }
        }
        .explanation {
            font-size:10px;
            padding-left:20px;
            line-height:14px;
            color:#4b4b4b;
            padding-right:0;
            margin-right:0;
        }
    }
}
.table {
    &.inside-borders {
        tr {
            &:first-child {
                td {
                    border-top: 0;
                }
            }
            td {
              border:1px solid #e5e5e5;
                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    width: 12.5%;
                    border-right: 0;
                }
                .form-group {
                    margin: 0;
                }
                .checkbox,
                .radio {
                    padding-top: 0;
                }
            }
            &:last-child {
                td {
                    border-bottom: 0;
                    padding-bottom:0;
                }
            }
        }
    }
    thead {
        tr {
            th {
                font-size:16px;
                font-weight:700;
                border-bottom:1px solid #e5e5e5;
                padding-bottom:25px;
                // padding-left:0;
            }
        }
    }
    tbody {
        tr {
            td {
                &.nopadding {
                    padding:0;
                }
            }
        }
    }
    &.clickrows {
        tbody {
            tr {
                &:hover,
                &.clicked {
                    cursor:pointer;
                    td {
                        background:#fafafa;
                        a {
                            &:hover, &:active, &:focus {
                                text-decoration:none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.funds .table.documents,
.table.documents,
.table.dataTable {
    max-width:100% !important;
    thead {
        th {
            border-right:1px solid #ddd;
            border-bottom:1px solid #ddd;
            padding: 12px 15px; 
            &:first-child {
                border-left:none;
            }
            &:last-child {
                border-right:none;
            }
        }
    }
    tbody {
        tr {
            td {
                border-right:1px solid #ddd;
                border-bottom:1px solid #ddd;
                padding: 12px 20px;
                &.dataTables_empty {
                    padding:40px 0;
                }
                &:first-child {
                    border-left:none;
                }
                &:last-child {
                    border-right:none;
                }
            }
        }
        tr:last-child {
            td {
                border-bottom:none;
            }
        }
    }
}
.table.results {
    thead {
        th {
            border-right:1px solid #ddd;
            border-left:1px solid #ddd;
            padding: 12px 15px;
            &:first-child {
                border-left:none;
            }
            &:last-child {
                border-right:none;
            }
        }
    }
    tbody {
        tr {
            td {
                font-weight:300 !important;
                border:1px solid #ddd;
                &:first-child {
                    border-left:none;
                }
                &:last-child {
                    border-right:none;
                }
            }
        }
        tr:last-child {
            td {
                border-bottom:none;
            }
        }
    }
}

.tooltip.top {
    &.fade.in {
        opacity:1;
    }
    .tooltip-inner {
        max-width:320px;
        line-height:22px;
        padding:12px 12px;
        font-size:14px;
        background:#eeb013;
    }
    .tooltip-arrow {
        border-top-color:#eeb013;
    }
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: black;
}

/**
 * Layouts app
 */

h1 {
    font-size:30px;
    font-weight:700;
}

.topheader {
    padding: 40px 25px 40px 25px;
    background: #fff;
    margin-bottom: 1px;
    i {
        font-size: 20px;
        color: #eeb013;
    }
    .logout {
        margin-left: 15px;
    }
}

.topbar {
    padding: 20px 25px;
    background: #fff;
    border-bottom:1px solid #f2f2f2;
}

.subheader {
    padding: 30px 0;
    background: #fff;
    h1 {
        font-size: 32px;
        color: #4b4b4b;
        line-height: 40px;
        font-weight: 700;
        margin: 0 0 10px 0;
    }
    h2 {
        font-size: 28px;
        color: #4b4b4b;
        font-weight: 300;
        margin: 0 0 10px 0;
    }
    h3 {
        font-size: 14px;
        color: #ef9147;
        font-weight: 700;
        margin: 0 0 0 0;
        .normal {
            font-weight: 300;
        }
    }
}

.main-content-wrapper {
  padding-bottom: 250px;

  @include media-breakpoint-down(md) { 
    padding-bottom: 0;
  }
  
  .container {
    padding-top: 20px;
  }
}


/**
 * Funds
 */

.funds {
    .table-hover>tbody>tr:hover {
        background: none;
    }
    .table>thead>tr>th {
        border-bottom: 0;
        padding: 12px 20px;
        padding-bottom: 15px;
        font-size: 14px;
        white-space: nowrap;
    }
    .table>tbody>tr>td {
        font-size: 16px;
        font-weight: 500;
        padding:0;
        border: none;
        white-space: nowrap;
    }
    .table>tfoot>tr>th {
        white-space: nowrap;
    }
    .table {
        // border-collapse: unset;
        
        .fundbar {
            color:#fff;
            font-weight:bold;
            padding: 12px 20px;
        }
        .funddata {
            background:#fafafa;
            padding: 12px 20px;
            border-bottom: 1px solid #dedede;
            border-left: 1px solid #dedede;
            width:1%;
            &.grow {
                width:auto;
            }
        }
        .contain-buy-btn {
            border-left: 0;
            border: 1px solid #dedede;
            width:25%;
            .btn {
                margin-top: -1px;
                &.btn-add {
                    padding-right: 84px !important;
                }
            }
            &.buy {
                border-color: #eeb013;
            }
            &.add {
                border-color: #75bd6d;
            }
        }
        & td.spacer-cell {
            height: 30px;
        }
        & td {
            .btn {
                width: 100%;
                height: 50px;
                padding: 11px 12px;
                border-top-left-radius:0;
                border-bottom-left-radius: 0;
                &.square {
                    border-radius: 0;
                }
            }
        }
        & td.image-cell {
            padding-right: 15px;
            padding-left: 8px;
            // padding-top: 6px;
        }
        .closed {
            width: 100%;
            height: 50px;
            padding: 11px 12px;
            border-right:1px solid #ddd;
            border-bottom:1px solid #ddd;
            margin-bottom: -1px;
        }
    }
    .table>tfoot>tr>th,
    .table>tfoot>tr>td {
        border: 0;
        font-size: 16px;
        font-weight: 700;
        padding: 12px 20px;
    }
    .table .small {
        font-size: 10px;
        font-weight: 300;
    }
}
.funddetail {
    table {
        tbody {
            tr {
                td {
                    &.image-cell {
                        border:none;
                    }
                }
            }
        }
    }
}

.container { 
    .jumbotron {
        padding:24px 30px;
        padding-left:30px;
        padding-right:30px;
        margin-bottom: 40px;
        position:relative;
        background-image:url('/images/jumbotron-bg-1.png');
        background-repeat:no-repeat;
        background-position-y: bottom;
        background-position-x: right;
        background-position: bottom right;
        h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
            margin-top:0;
            margin-bottom: 30px;
        }
        .btn-danger {
            color: #fff;
            background-color: #086375;
            border: 2px solid #fff;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            font-size: 19px;
            padding: 8px 30px;
            &:hover {
                color: #086375;
                background-color: #fff;
                border-color: #064f5d;
            }
        }
        &.style1 {
            background-color:#086375;
            border-radius:0;
            color:#fff;
            h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
                color:#fff;
                line-height:46px;
                font-size:36px;
                font-weight:600;
                margin-bottom:0;
            }
        }
        &.style2 {
            background-color:#086375;
            border-radius:0;
            color:#fff;
            padding:40px 50px;
            h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
                color:#fff;
                line-height:1em;
                font-weight:600;
            }
        }
        &.style3 {
            background-color:#269e87;
            border-radius:0;
            color:#fff;
            h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
                color:#fff;
                line-height:36px;
                font-size:26px;
                font-weight:600;
                margin-bottom:30px;
            }
        }
        &.style4 {
            background-color:#ef9008;
            border-radius:0;
            color:#fff;
            h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
                color:#fff;
                line-height:36px;
                font-size:26px;
                font-weight:600;
                margin-bottom:30px;
            }
        }
        &.style5 {
            background-color:#24bbc2;
            border-radius:0;
            color:#fff;
            padding:40px 50px;
            h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
                color:#fff;
                line-height:1em;
                font-weight:600;
            }
        }
    }
}

/**
 * Get done
 */

.get-done {
    .panel-body {
        padding-right: 10px;
    }
    ul.getDoneList {
        li {
            color:#4b4b4b;
            a {
                color:#4b4b4b;
                text-decoration:none;
            }
            &.active {
                color: #ec8f3e; 
                font-weight:700;  
            }
          }
        }
    ul {
        padding-left: 25px;
        padding-right: 10px;
        li {
            font-size: 15px;
            line-height: 26px;
            list-style: none;
            margin-bottom: 10px;
            font-weight: 500;
     
            &:before {
              content: "\e258";
              font-family: 'Glyphicons Halflings';
              font-size: 12px;
              float: left;
              margin-top: 0px;
              margin-left: -25px;
              color: #a7a9aa;
              list-style: none;
            }

            .list-button {
              background: none;
              border: none;
              font-weight: 500;
              margin: 0;
              padding: 0;
            }
        }
    }
    ul.activefundsList {
        padding-left: 0px;
        li {
          list-style: none;
          margin-bottom: 25px;
            a {
                color:#fff;
                display: flex;
                position: relative;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                font-size: 12px;
                line-height: 26px;
                font-weight: 500;
                padding:10px 28px 10px 10px;
                &:after {
                    content: "\e258";
                    font-family: 'Glyphicons Halflings';
                    font-size: 12px;
                    position:absolute;
                    right: 10px;
                    top:50%;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                    color: #fff;
                    list-style: none;
                }
                div:last-child {
                    text-align: right;
                    font-size: 13px;
                }
            }
            &:before {
                content: none;
            }
        }
        li.totals {
            margin-top:30px;
            margin-bottom:0;
            // padding-top:15px;
            // padding-right:15px;
            padding:10px 30px 10px 10px;
            background:none;
            border:none;
            border-top:1px solid #ece9e8; 
            &:before {
                content: none;
            }
        }
    }
    .progress-list {
        .panel-heading {
            font-size: 20px;
        }
        ul {
            padding: 0px;

            li .line {
                content: ' ';
                width: 4px;
                height: 42px;
                background: #e9e9e9;
                display: block;
                position: absolute;
                top: 12px;
                left: -14px;
            }
            li .dot {
                content: ' ';
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #9ae1e5;
                display: block;
                position: absolute;
                top: 8px;
                left: -16px;
            }
            // li .checked {
            //     background: $progress-list-done;
            // }
            // li .todo {
            //     background: $progress-list-todo;
            // }
            li .grayedout {
                color:#b8b8b8;
            }
            li {
                font-size: 14px;
                display: block;
                margin: 16px 0px 16px 20px;
                position: relative;
            }
            li::before {
                display: none;
            }
        }
    }
}

// $progress-list-done: #ef9008;
// $progress-list-todo: #9ae1e5;

// @mixin generate-list-done-selectors($n, $parent, $child, $color, $isel: "") when ($n > 1){
//     $sel: #{", ${parent} li:nth-child(${n}) ${child}${isel}"};
//     @include generate-list-done-selectors($n - 1, $parent, $child, $color, $sel);
// }
// @mixin generate-list-done-selectors($n, $parent, $child, $color, $isel: "") when ($n = 1){
//     $sel: #{"${parent} li:nth-child(1) ${child}${isel}"};
//     ${sel} {
//         background: $color;
//     }
// }

// @mixin generate-list-done($n) when ($n > 0){
//     @include generate-list-done($n - 1);
//     @include generate-list-done-selectors($n, ".get-done .progress-list ul.step-${n}", ".dot", $progress-list-done);
//     @include generate-list-done-selectors($n - 1, ".get-done .progress-list ul.step-${n}", ".line", $progress-list-done);
//     @include generate-list-done-selectors($n, ".get-done .progress-list ul.step-${n}", ".todo", $progress-list-todo);
// }

// @include generate-list-done(9);


/**
 * Dashboard
 */
.dashboardTable {
    tbody {
        tr {
            td {
                border:none;
                i {
                    color:#a6a7a8;
                }
                a {
                    color:#4b4b4b;
                    text-decoration:none;
                }
            }
        }
    }
}
.messagesTable {
    tbody {
        tr {
            td {
                padding:10px 8px;
            }
        }
        tr.read {
            td {
                color:#b8b8b8;
                a {
                    color:#b8b8b8; 
                    text-decoration:none;                   
                }
                &.datefield {
                    a {
                        color:#222222;
                    }
                }
            }
        }
        tr.unread {
            td {
                font-weight:bold;
                color:#222222;
                a {
                    color:#222222;    
                    text-decoration:none;                
                }
                &.datefield {
                    a {
                        color:#222222;
                        font-weight:300;
                    }
                }
            }
        }
    }
}

/**
 * Database page
 */

 .removable-holding {
    cursor: pointer;
    
    .open-holding-remove-modal {
        color: red;
        display: none;
        font-weight: bold;
        text-decoration: none;
    }

    &:hover {
        .value-formatted {
            display: none;
        }
        .open-holding-remove-modal {
            display: initial;
        }
    }
 }

/**
 * Feedback
 */
.feedback {
    p {
        font-size:18px;
    }
}

/**
 * Messenger views
 */

.messenger {
    &.index {
        table {
            tr {
                a {
                    color: #000;
                    font-weight: bold;
                }
                &.read {
                    a {
                        font-weight: normal;
                    }
                }
            }
        }
    }
    // &.show {}
    // &.create {}
}

.printTable {
    color:#4b4b4b;
}

/**
 * RESPONSIVE
 */
// MD
@media (max-width:1200px) {
    .getDoneList {
        li {
            display: inline-block;
            margin-right: 46px;
        }
    }
}
// SM
@media (max-width:992px) {
    .navbar-main .nav-top > li > a,
    .navbar-main .nav-bottom .nav > li > a {
        padding-left:15px;
        padding-right:15px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .table thead tr th {
        font-size: 13px;
        padding:5px;
    }
    .funds .table > tbody > tr > td {
        font-size: 13px;
    }
    .funds .table > tfoot > tr > th, 
    .funds .table > tfoot > tr > td {
        font-size: 13px;
    }
    .control-label {
        font-size:15px;
    }
}

@media (min-width:768px) {
    .canvas {
        position:static !important;
    }
    .off-canvas {
        display:none;
    }
}
// XS
@media (max-width:767px) {

    #createaccountbutton1 {
        margin-bottom:20px;
    }

    .navbar-brand {
        float:none;
        height:auto;
        > img {
            margin:auto;
        }
    }
    .canvas {
        background:#fff;
    }
    .xs-font-h1 {
        font-size:6vw !important;
    }
    .xs-font-h2 {
        font-size:5vw !important;
    }
    .text-xs-center {
        text-align:center;
    }
    .text-xs-left {
        text-align:left;
    }
    .navbar-main {
        padding-top:0;
    }
    .navbar {
        border:none;
    }
    .navbar-nav {
        margin:0;
    }
    .nav > li {
        display:inline-block;
    }
    .navmenu-fixed-left .nav > li {
        display:block;
    }
    .navbar-toggle .icon-bar {
        background:#000;
    }
    .navbar-main .nav-top > li > a {
        padding:5px;
        font-size:14px;
    }
    .navbar-main .nav-bottom .nav > li > a {
        padding:5px;
        font-size:14px;
    }
    .btn {
        max-width: 100%;
        height: auto;
        white-space: normal;
    }
    .modal-backdrop {
        z-index: 500;
    }
    .modal {
        z-index:9999;
    }
    .navmenu-default, .navbar-default .navbar-offcanvas {
        background-color:#cdf1f3 !important;
    }
    body .navmenu-default .navmenu-nav>li>a, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>li>a,
    body .navbar-nav > li > a {
        border-radius: 0;
        color: #000;
        font-weight: 700;
        font-size: 18px;
        border: none;
        padding: 10px 20px;
        text-decoration: none;
    }
    body .navmenu-default .navmenu-nav>li>.nav>ul>li>a, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>li>.nav>ul>li>a {
        border-radius: 0;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        border: none;
        padding: 10px 20px;
        text-decoration: none;
    }
    body .navmenu-default .navmenu-nav>.active>a, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>.active>a, 
    body .navmenu-default .navmenu-nav>.active>a:hover, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>.active>a:hover, 
    body .navmenu-default .navmenu-nav>.active>a:focus, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>.active>a:focus,
    .navmenu .nav-bottom > ul > li.active,
    .navmenu .nav-bottom > ul > li.active > a {
        background-color:#fff;
        color:#eeb325;
    }
    body .navmenu-default .navmenu-nav>li>a:hover, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>li>a:hover, 
    body .navmenu-default .navmenu-nav>li>a:focus, 
    body .navbar-default .navbar-offcanvas .navmenu-nav>li>a:focus {
        background-color:#fff;
        color:#eeb325;
    }

    .navmenu .nav-bottom .fondsensub {
        display:none;
    }
    .navmenu .nav-bottom > ul > li > a {
        padding-left: 40px;
        font-size:16px;
    }

    .funds .table td.image-cell img {
        width:70px !important;
        max-width:none !important;
    }
    .topheader {
        padding-top:20px;
        padding-bottom:0;
        padding-right:0;
        padding-left:0;
        .versionWrap {
            margin-bottom:20px;
        }
        .iconwrap {
            margin:0;
            padding:0;
            vertical-align: bottom;
        }
        .logout {
            margin-left:25px;
            padding: 2px 14px;
        }
    }
    .footer {
        position:relative;
        height:auto;
        bottom:auto;
        margin-top:0;
        font-size: 12px;
        color: #969696;

        .container {
            padding:20px 15px;
        }
    }
    body {
        margin-bottom:0 !important;
    }
    .subheader {
        padding-left:0;
        padding-right:0;
        padding-top:20px;
    }
    .panel-default {
        .panel-heading {
            position:relative;
            margin: 0 20px;
            font-size:18px;
        }
        .panel-body {
            position:relative;
            padding: 25px 20px 15px 20px;
        }
    }
    .panel-bordered {
        margin-right:5px;
        margin-left:5px;
        .panel-heading {
            margin: 0 20px;
            font-size:18px;
            position:relative;
        }
        .panel-body {
            padding: 25px 20px 15px 20px;
            .text-right {
                text-align:left;
            }
        }
    }
    .main-content-wrapper .container {
        // padding:0;
        .row {
            margin-right:0;
            margin-left:0;
            & > .col-12 {
                padding-left:0;
                padding-right:0;
            }
        }
    }
    .table-responsive {
        position:relative;
    }
    .panel .draghand {
        color:#000;
    }
    .draghand {
        position:absolute;
        right:5px;
        top:20px;
        opacity:0.8;
        .glyphicon {
            font-size:22px;
        }
    }
    .container .jumbotron.sunoven {
        margin-left: 0 !important;
        margin-right: 0 !important;
        .h2 {
            line-height: 31px !important;
            font-size: 20px !important;
        }
        .absoluteimage {
            position: relative !important;
            right: auto !important;
            bottom: auto !important;
            margin-top: 31px !important;
            max-width: 100% !important;
        }
    }
}  

.btn-warning.active, 
.btn-warning:active, 
.btn-warning:hover, 
.btn-warning,
.open > .btn-warning.dropdown-toggle {
    background:#eeb013;
}

.draghand{
  animation: animationFrames ease-in-out 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  -webkit-animation: animationFrames ease-in-out 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -moz-animation: animationFrames ease-in-out 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -o-animation: animationFrames ease-in-out 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -ms-animation: animationFrames ease-in-out 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
}

@keyframes animationFrames{
  0% {
    transform:  translate(0px,0px)  ;
  }
  50% {
    transform:  translate(-20px,0px)  ;
  }
  100% {
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes animationFrames{
  0% {
    -moz-transform:  translate(0px,0px)  ;
  }
  50% {
    -moz-transform:  translate(-20px,0px)  ;
  }
  100% {
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  50% {
    -webkit-transform:  translate(-20px,0px)  ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform:  translate(0px,0px)  ;
  }
  50% {
    -o-transform:  translate(-20px,0px)  ;
  }
  100% {
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform:  translate(0px,0px)  ;
  }
  50% {
    -ms-transform:  translate(-20px,0px)  ;
  }
  100% {
    -ms-transform:  translate(0px,0px)  ;
  }
}

.navbar-toggle {
  float: left;
  margin-left: 15px;
}

.navmenu {
  z-index: 1 !important;
}

.canvas {
  position: relative;
  left: 0;
  z-index: 2;
  min-height: 100%;
  // background: #fafafa;
}

@media (min-width: 0) {
  .navbar-toggle {
    display: block; /* force showing the toggle */
  }
}
@media (min-width: 768px) {
  .navbar-toggle {
    display:none;
  }
}
@media (min-width: 992px) {
  body {
    padding: 0;
  }
  .navbar {
    right: auto;
    background: none;
    border: none;
  }
  .canvas {
    padding: 0;
  }

}
.hoverpanel {
    -webkit-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    &:hover {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);

    }
}
.eh-panel {
    min-height:290px;
    position:relative;
    p {
        padding-bottom:35px;
        font-size: 16px;
    }
    .btn {
        position:absolute;
        bottom:20px;
        left:20px;
    }
    .btn.rel {
        position: relative;
        bottom: auto;
        left: auto;
    }
    .centered {
        margin: 15px auto 0px auto;
    }
    .watchit {
        position: relative;
        padding-left: 30px;
        padding-top: 30px;
        padding-bottom: 20px;
        .mdi {
            position: absolute;
            left:0;
            top: 26px;
            font-size: 20px;
            &.green {
                color: #189e86;
            }
            &.primary {
                color: #f7ae49;
            }
        }
        a {
            &.green {
                color: #189e86 !important;
            }
            &.primary {
                color: #f7ae49 !important;
            }
        }
    }
}
@media (max-width: 1399px) {
    a.btn-green,
    .btn-green,
    .btn.btn-primary {
        font-size: 14px;
    }
}

@media (max-width:992px) {
    .eh-panel {
        min-height:360px;
    }
}

.socialicon {
    color: #fff;
    display: inline-block;
    background: #09bdc6;
    height: 31px;
    width: 31px;
    line-height: 33px;
    font-size: 16px;
    border-radius: 25px;
    text-align: center;
}

.socialicon-trustpilot {
    padding-left: 31px;
    position: relative;

    &:before {
        background-image: url('/images/trustpilot-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
        content: ' ';
        display: inline-block;
        position: absolute;
        left: 0;
        height: 31px;
        width: 31px;
    }
}

.text-decoration-none {
    text-decoration: none;
}

/*
.modal-styled {
    .modal-content {
        border: 3px solid #ef9008;
        border-radius: 2px;
    }
    .modal-header {
        font-size: 20px;
        color: #f7ae49;
        margin: 10px 30px 0 30px;
        padding-left:0;
        padding-right:0;
        .close {
            color: #09bdc6;
            opacity: 1;
            font-size: 28px;
            font-weight: 500;
        }
    }
    .modal-body {
        padding-bottom: 20px;
    }
}
*/

.multipleChoice {
    h1 {
        font-family: Montserrat, Arial, Helvetica, sans-serif;
        font-size: 34px;
        color: #e89422;
        margin-bottom: 40px;
    }
    h3 {
        font-family: Montserrat, Arial, Helvetica, sans-serif;
        font-size: 22px; 
        color: #e89422;
    }
    p, td {
        font-weight: 300;
        font-size: 18px;
        color: #4b4b4b;
    }
    ul {
        list-style:none;
        padding:0;
        margin:0;
        margin-bottom: 11px;
        li {
            position: relative;
            margin:0;
            padding: 0;
            font-size: 16px;
            color: #4b4b4b;
            padding-left: 30px;
            font-weight: 300;
            .glyphicon {
                position: absolute;
                left:0;
                top: 8px;
            }
        }
    }
    table {
        margin: 11px 0;
    }
    .panel {
        padding: 30px;
        background: #fefefe;
        border-radius: 20px;
        h3 {
            color:#fff;
            font-weight:bold;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: -30px -30px 30px -30px;
            padding: 20px 50px 20px 50px;
            border-bottom: 1px solid #ece9e8;
        }
        .form-control {
            border: none;
            background: none;
            font-size: 18px;
            height: auto;
            font-weight: 300;
        }
        .details {
            font-size: 14px;
            line-height: 30px;
            font-weight: 500;
            padding-left: 20px;
            padding-top: 10px;
            .glyphicon {
                margin-right: 10px;
                font-size: 12px;
                color: #ef9008;
            }
        }
        .collapseButton {
            .glyphicon-triangle-top:before {
                content: "\E253" !important;
                color: #c1c1c1;
                font-size: 15px;
            }
            &.collapsed {
                .glyphicon-triangle-top:before {
                    content: "\E252" !important;
                    color: #c1c1c1;
                    font-size: 15px;
                }
            }
        }
    }
    .agree {
        font-weight: 300;
        font-size: 16px;
        line-height: 27px;
    }
}
.container .choicebanner-wrapper {
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    .jumbotron.choicebanner {
        background-color: #e8c0bc;
        margin: 2px;
        .h2 {
            color: #086375;
        }
        .description {
            width:60%;
        }
        .btn {
            margin-right:20px;
        }
    }
}

.identification_fill_in_later {
    padding: 30px;
    .control-label {
        padding-bottom: 15px;
        text-align: left;
    }
    .specialRadio {
        border: none;
    }
}
@media (max-width: 992px) {
    .choicebanner {
        .h2 {
            font-size: 20px !important;
            line-height: 24px !important;
        }
        .description {
            width:100%;
        }
        .btn {
            display: block;
            margin: auto;
            margin-bottom: 20px;
            font-size: 13px;
            padding: 9px 25px;
        }
    }
}
.dropdown-menu > .active > a {
    background-color: #eeb325;
    &:hover {
        background-color: #eeb325;
    }
}

.dataTables_filter .documents-btn-filter-group .btn.btn-secondary.documents-btn-filter.selected{
    background: #26ebf5;
    border-bottom: 2px solid #09bdc6;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dataTables_filter .documents-btn-filter-group .btn.btn-secondary.documents-btn-filter.selected:focus{
    outline: none;
    box-shadow: none;
}

.dataTables_filter .documents-btn-filter-group .btn.btn-secondary.documents-btn-filter{
    margin-right: 3px;
}


.dataTables_filter .documents-btn-filter-group {
    margin-bottom: 10px;
}
.panel-process-dates {
    .table-responsive {
        padding-left: 15px;
        padding-right: 15px;
    }

    .btn-add-process-date{
        margin-bottom: 20px;
    }

    .remove-process-date{
        cursor: pointer;
    }
}

tr.details_header
{
    cursor:pointer;
}
tr.details_header th {
    text-align: center;
    word-wrap: normal
}
.detail_table {
    width: 100%;
    word-wrap:break-word;
    table-layout: fixed;
}

.pincode-input-container input{
    border: 1px solid;
}

.riskVignet {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 70px;
    color: #fff;
    border: 1px solid #fff;
    background: #09bdc6;
    font-size: 14px !important;
    z-index: 999;
    max-width: 100%;
    border: none;
    background: none;
    img {
        max-width:100%;
    }
}
@media (max-width: 991px) {
    .dataTables_filter .documents-btn-filter-group 
    {
        display:flex;
        flex-wrap: wrap;
        width: 100%;
        .btn.btn-secondary.documents-btn-filter{
            margin: 3px;
            border-radius: 0 !important;
            flex-grow: 1;
        }
    }
}
@media (max-width: 768px) {
    .riskVignet {
        right: 20px;
        height: auto !important;
        width: 120px;
        line-height: 1.6em !important;
        font-size: 13px !important;
        white-space: normal;
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    }
}

.phone_info, .email_info {
    display: none;
    border: 1px solid #cdf1f3;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
}

.verification_method {
    display: block;
    width: 70%;
    height: 60px;
    overflow: hidden;
    margin: 7px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-decoration: none;
}
.verification_method > img {
    display: block;
    float: left;
    width: auto;
    height: 70%;
    margin: 3%;
}
.verification_method p {
    padding: 18px 0;
}
.verification_method:hover {
    transition: 0.2s;
    border-color: #cdf1f3;
    text-decoration: none;
}

#g2fa_disabled, #email_disabled, #sms_disabled {
    background: #f2f2f2;
    border: none;
    cursor: auto;
}

#g2fa_disabled i, #email_disabled i, #sms_disabled i {
    color: green;
    cursor: pointer;
}

.g2fa-header {
    color: #ef9147;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.g2fa-body {
    border-bottom: 1px solid #ddd;
}

.g2fa-modal-description h4 {
    text-align: center;
}

.g2fa-sample-image {
    margin: 0 auto;
    max-width: 200px;
    display: block;
}

.g2fa-modal-description p {
    text-align: center;
    color: #999;
    font-weight: bold;
}

.g2fa-modal-description p:last-child {
    color: #ef9147;
}

.g2fa-body .form-control.pincode-input-text.first {
    border: 1px solid #ddd;
    border-right: none;
}

.g2fa-body .form-control.pincode-input-text.last {
    border: 1px solid #ddd;
    border-left: none;
}

.g2fa-body .form-control.pincode-input-text.mid {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: none;
    border-right: none;
}

.g2fa-body .form-control.pincode-input-text {
    padding: 20px 10px;
    font-weight: bold;
    color: #999;
}

.g2fa-body .form-control.pincode-input-text:focus {
    box-shadow: none;
}

.g2fa-body .form-control.pincode-input-text::placeholder {
    color: #ddd;
    opacity: 1;
    font-weight: bold;
}

.fund_value_per {
    color: gray;
    font-style: italic;
    font-size: 12px;
}

.total_value_per {
    color: gray;
    font-size: 12px;
}

#depositAccountMenuItem {
    display: none;
}

.iti {
    &.iti--separate-dial-code .iti__selected-flag,
    &.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
        background: none;
    }
    .help-block.error-help-block  {
        position: absolute;
        top: 100%;
        width: 100%;
    }
}
@supports ((display: -ms-grid) or (display: grid)) {
    .account-button-grid {
      display: flex;  
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      gap: 25px 40px;
    //   max-width: 450px;
      margin:auto;
    }
    @media (max-width: 768px) {
        .account-button-grid {
            .account-select-button {
                width: 100%;
            }
        } 
    }
}

.account-select-button {
    display: flex;
    flex-direction: column;
    font-weight:500;
    font-size: 18px;
    background: transparent;
    border:none;
    color: #fff;    
    border-radius: 18px;
    padding: 2rem 0 1rem 0;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    min-width: 30%;
    .image {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        min-height: 100px;
        align-items: center;
    }
    .btn {
        background: #09bdc6;
        border-color: #09bdc6;
        &:focus, 
        &:hover {
            background: #09bcc6bb;
            border-bottom: 2px solid #09bcc6bb;
            box-shadow: none;
            outline: none;
        }
    }
}
#change-phone .iti.iti--allow-dropdown.iti--separate-dial-code,
#store-landline-phone .iti.iti--allow-dropdown.iti--separate-dial-code
{
    margin-left: 15px;
}

.onboarding .validateandproceed {
    margin-top: 30px;
}

.sellform {
    .panel-heading {
        color: #4b4b4b;
        border-color: #36b8c3;
    }
    p, ul, li {
        font-size:14px;
        color: #4b4b4b;
        line-height:1.6;
    }
    .error-help-block {
      color: #d22626;
      font-weight: bold;
      margin: 10px 0 0 0 !important;
      padding: 0 28px;
    }
    .alert-withimage {
        padding-left: 60px;
        padding-right: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -20px;
        margin-right: -20px;
        border-top-width: 3px;
        border-bottom-width: 3px;
        border-right: 0;
        border-left: 0;
        color: #4b4b4b;
        min-height: 245px;
        background-color: #f1f9fa;
        background-image: url('/images/alert-bg.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        @media (min-width: 769px) {
          margin-left: -30px;
          margin-right: -30px;
        }
        p {
          color: #4b4b4b;
          @media (min-width: 769px) {
            width:60%;
          }
        }
        @media (min-width: 769px) {
            margin-left: -30px;
            margin-right: -30px;
        }
    }
    .contains-icon {
        position: relative;
        .icon {
            position: absolute;
            top: 3px;
            left:-30px;
            color: #32bbc5;
            font-size: 20px;
        }
    }
    ul:not(.breadcrumbs-content) {
        list-style: none; /* Remove default bullets */
        li::before {
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #09bdc6; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -20px; /* Also needed for space (tweak if needed) */
          }
    }
    table {
        margin-bottom: 40px;
        thead {
            th {
                padding: 10px 0;
                font-size: 22px;
                color: #ef9008;
                border-bottom: 3px solid #32bbc5;
            }
        }
        tr {
            &:first-child {
                td {
                    padding-top: 20px;
                }
            }
            td {
                padding:12px 0;
                &:first-child {
                    font-weight:bold;
                }
                &:last-child {
                    text-align:right;
                    padding-right: 20px;
                }
            }
        }
    }
}