.page-people-dashoverzicht {
  .col-account-dashboard {
    margin-bottom: 40px;
    
    .box {
      padding: 45px 50px;
      height: 100%;

      .box-image {
        margin-bottom: 40px;
        text-align: center;
        min-height: 110px;
        
        @include media-breakpoint-down(md) { 
          margin-bottom: 20px;
        }
      }
      
      .box-heading {
        font-weight: 600;
      }
  
      .box-content {
        a, 
        button {
          color: #131523;
          display: block;
          font-size: 14px;
          font-weight: 400;
          margin: 0 0 15px 0;
  
          &:last-child {
            margin-bottom: 0;
          }
        }

        .newsletter-submit {
          background: none; 
          border: none; 
          cursor: pointer;
          padding: 0; 
        }
      }
    }

    @include media-breakpoint-down(md) { 
      padding-right: 0;
      padding-left: 0;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}