.page-documents {
  .dataTables_filter {
    label {
      display: none;
    }
  }

  .dt-container {
    .dt-top-nav {
      display: none;
    }

    .dt-info {
      display: none;
    }
  }

  .table-container {
    @include media-breakpoint-up(md) {
      padding-bottom: 70px !important;
    }

    .table-heading {
      align-items: center;
      display: flex;
  
      .form-select {
        background-color: #e6e9f4;
        border-color: #d9e1ec;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
        background-position:  calc(100% - 12px) 0.9em, calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em !important;
        background-size: 10px 10px, 10px 10px, 1px 1.5em !important;
        color: #5a607f;
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        margin-left: 20px;
        max-width: 200px;
      }
    }

    table.dataTable {
      tbody {
        tr {
          td {
            &:nth-of-type(2) {
              width: 60%;
            }
          }
        } 
      } 
    } 

    table.dataTable {
      border-collapse: collapse !important;
      
      thead {
        th {
          &.action-edit-th,
          &.action-show-th {
            padding-right: 0;
            max-width: 90px;
          }
        }
      }

      tbody {
        tr {
          td {
            border-right: 0;
            border-left: 0;
            
            &.action-show,
            &.action-download {
              a {
                color: #fff;
                font-size: 14px;
                font-weight: 400;
              }
              
              i {
                margin-left: 10px;
              }
            }
            &.action-show {
              padding-right: 0;
              max-width: 90px;

              a {
                background-color: #57bbb7;
              }
            }
            &.action-download {
              a {
                background-color: #c5cbe5;
                min-width: 120px;
              }
            }

            &:last-child {
              &:not(:first-child):not(.action-download) {
                border-left: 1px solid #ddd;
              }
            } 
          }
        }
      }
    }

    .dt-info,
    .dt-length {
      display: none;
    }

    .dataTables_wrapper {
      .col-sm-5 {
        display: none;
      }
    }
  } 
  
  .dt-paging {
    margin: -100px 0 0 30px !important;
    padding-right: 30px;

    .dt-paging-button {
      border-radius: 4px;
      margin: 0 2px;
      transition: all .2s ease-in-out;

      * {
        background: none;
        border: 0;
      }

      .page-link {
        margin-top: 0;
        margin-bottom: 0;
      }

      a {
        color: #5a607f;
        display: block;
        padding: 5px 14px;
        text-decoration: none;
        height: 100%;
      }

      &.previous, 
      &.next {
        font-family: "bootstrap-icons";
        line-height: 1.2rem;
        
        a {
          display: block;
          font-size: 0;
          padding: 0;
          position: relative;
          height: 34px;
          width: 34px;

          &::before {
            font-size: 16px;
            line-height: 34px;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            height: 34px;
            width: 34px;
          }
        }
      }
      &.previous {
        a {
          &::before {
            content: "\F12F";
          }
        }
      }
      &.next {
        a {
          &::before {
            content: "\F138";
          }
        }
      }

      &.active {
        background-color: #ecf2ff;

        .page-link {
          color: #5a607f;
        }
      }

      &:hover {
        background-color: #ecf2ff;
      }
      
      &.disabled {
        opacity: .4;
        
        &:hover {
          background: none;
        }
      }
    }
  }

  .table.documents tbody tr td.dataTables_empty, .table.documents tbody tr td.dataTables_empty, .table.dataTable tbody tr td.dataTables_empty {
    padding: 40px 0 0;
  }

  .nav-pagination {
    margin: -120px 0 0 20px;
  }

  @include media-breakpoint-down(md) { 
    .table-container {
      padding: 0 0 60px 0;
      
      .table-heading {
        margin-bottom: 0;
        padding: 20px;
      }

      .table {
        margin-top: 0 !important;
      }
    }
    
    .nav-pagination {
      margin: 30px !important;
    }
    .pagination {
      margin: -50px 0 0 0 !important;
    }
  }
}



 