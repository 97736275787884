.nav-account {
  margin-bottom: 30px;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  z-index: 2;

  .nav-link {
    border-radius: 4px;
    color: #131523;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    text-align: left;
    max-width: 100%;

    &.active {
      background-color: #7b8bc5;
      color: #fff;
    }
  }

  @include media-breakpoint-up(lg) { 
    position: sticky;
    top: 20px;
  }
}

.page-account {
  .account-partial {
    margin-bottom: 50px;
    
    @include media-breakpoint-down(md) { 
      margin-bottom: 20px;
    }
  }

  .input-group-addon {
    display: none;
  }

  &.page-gegevens {
    .account-partial {
      &.second-account-information {
        .form-group {
          display: inline-block;
          margin-bottom: 10px;
          width: 49%;
  
          &:last-of-type {
            display: none;
            margin-left: auto;
          }
        }
      }
    }
  }
}

.auth-list-group {
  margin-bottom: 30px;

  .list-group-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;

    .list-group-item-heading {
      font-size: 16px;
    }
    
    .list-group-item-text {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    .list-group-item {
      flex-direction: column;
  
      .list-group-item-button {
        margin-top: 20px;
      }
    }
  }
}

.page-people-change-bankaccount {
  .justified {
    text-align: justify;
    text-align-last: center;
  }

  .panel-body {
    p {
      color: #5a607f;
      font-size: 14px;
      line-height: 22px;
    }

    .title {
      color: #535353;
      font-size: 16px;
    }
    
    .iban-label {
      color: #5a607f;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    .iban-input {
      border: 2px solid #57bcb7;
      border-radius: 4px;
      color: #5a607f;
      display: block;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 2px;
      margin: 30px auto;
      width: 220px;
    }
  }

  .gray-box {
    background: #f5f6fa;
    border-radius: 4px;
    margin-top: 70px;
    padding: 30px 20px;
  }

  .embedded-video {
    margin: 0 auto;
    height: auto;
    max-width: 475px;
  }

  .ldiv {    
    .validation-message {
      background: rgba(255, 255, 255, 0.5);
      border: 2px solid #57bcb7;
      border-radius: 4px;
      color: #57bcb7;
      font-size: 16px;
      font-weight: 600;
      padding: 10px 15px;
    }
  }
}

.passwordCheckList {
  &::before {
    top: 2px !important;
  }

  p {
    margin: 0 0 10px 0;
  }
}

.page-people-secondary {
  form {
    .form-group {
      display: inline-block;
      margin-bottom: 10px;
      width: 49%;

      &:last-of-type {
        display: block;
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
      }
    }
    
    @include media-breakpoint-down(sm) {
      .buttons-container {
        * {
          margin-right: auto !important;
          margin-left: auto !important;
          text-align: center !important;
        }

        .btn {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.page-account-depositaccount {
  .panel-stortingsrekeningen {
    .panel-heading {
      .icon-container {
        display: flex;
      }

      @include media-breakpoint-down(lg) {
        .text-container {
          margin-bottom: 15px;
        }
      }

      @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  
    .table-container {
      padding: 0;
    }

    .deleteButton {
      align-items: baseline;
      display: flex;
      font-size: 13px;
      justify-content: space-between;
      padding: 4px 6px;

      .glyphicon {
        margin-right: 4px;
      }
    }
  }
}
  
.personalform {
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-right: -24px;
    margin-left: -24px;
    
    .form-group {
      padding-right: 24px;
      padding-left: 24px;

      input {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(lg) { 
      .form-group {
        width: 48% !important;
        
        &:last-of-type {
          width: 100% !important;
        }
      }
    }

    @include media-breakpoint-down(sm) { 
      .form-group {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}

.idform {
  #upload-part {
    display: flex;
    flex-wrap: wrap;
    gap: 6%;

    .form-group {
      width: 46%;
    }
    
    @include media-breakpoint-down(xl) { 
      flex-direction: column;

      .form-group {
        width: 100% !important;
      }
    }
  }
}

.addressform {
  .numberaddition-manual {
    display: block;
    font-size: 12px;
    margin: -10px 0 10px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    label {
      letter-spacing: -0.7px;
    }
  }
}
