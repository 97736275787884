body { 
  .modal-content {
    padding: 20px;
    position: relative;
  
    .close {
      background: none;
      font-size: 24px;
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  #judgeModal, #inviteModal {
    .modal-body {
      padding: 20px;
    }
  }

  #idle-control-modal {
    background: rgba(0, 0, 0, 0.4);
  }

  #personAdminEdit, 
  #personAddressAdminEdit, 
  #berichtenAdminView {
    .help-block {
      font-size: 14px;
    }

    .char-count, 
    .char-max {
      font-size: 14px;
    }
  }
}
